import store from '../vuex/store'


export default {
  hasModule(moduleName) {
    if (store.state.companyModules.find(el => el.name == moduleName)) {
      return true
    } else {
      return false
    }
  }
} 