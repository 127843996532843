<template>
  <select
    :style="customStyle"
    v-model="selected"
    @change="updateOrderStatus(order.ocr, selected)"
    class="statusSelect"
    :class="computedStyle"
  >
    <option value="0">Pending</option>
    <option value="1">Paid</option>
    <option value="2">Delayed</option>
  </select>
</template>

<script>
export default {
  props: {
    order: Object,
    customStyle: Object
  },
  created() {
    if (!this.selected) {
      this.selected = this.order.paymentStatus
    }
  },
  data() {
    return {
      selected: this.order.paymentStatus
    };
  },
  watch: {
    order(newData) {
      this.selected = newData.paymentStatus;
    }
  },
  computed: {
    computedStyle() {
      switch (Number(this.selected)) {
        case 0:
          return "pending";
          break;
        case 1:
          return "paid";
          break;
        case 2:
          return "delayed";
          break;
        default: 
          break;
      }
    }
  },
  methods: {
    updateOrderStatus(ocr, newStatus) {
      this.$socket.emit("updateOrderStatus", { ocr, newStatus });
    }
  }
};
</script>

<style scoped lang="scss">


.statusSelect {
  padding: 7px 15px;
  border-radius: 30px;
  display: flex;
  white-space: nowrap;
  outline: none;
  height: 33px;
}

.statusSelect .fa {
  padding-right: 5px;
  top: 1px;
  position: relative;
}

.paid {
  background: #d8f0e6;
  color: #079927;
  border: 1px solid #bedfd1;
  font-size: 10pt;
  font-weight: bold;
}

.pending {
  background: #d6ddef;
  color: rgb(66, 69, 255);
  border: 1px solid #d3ceff;
  font-size: 10pt;
  font-weight: bold;
}

.delayed {
  background: rgb(240, 207, 207);
  color: rgb(194, 36, 36);
  border: 1px solid #ceacac;
  font-size: 10pt;
  font-weight: bold;
}

</style>