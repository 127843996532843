Number.prototype.padZero = function (len) {
  var s = String(this), c = '0';
  len = len || 2;
  while (s.length < len) s = c + s;
  return s;
}

Date.prototype.getWeek = function () {
  var date = new Date(this.getTime())
  date.setHours(0, 0, 0, 0)
  date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7))
  var week1 = new Date(date.getFullYear(), 0, 4)
  return (
    1 +
    Math.round(
      ((date.getTime() - week1.getTime()) / 86400000 -
        3 +
        ((week1.getDay() + 6) % 7)) /
      7
    )
  )
}

String.prototype.capitalizeFirstLetter = function () {
  try {
    return this.charAt(0).toUpperCase() + this.slice(1)
  } catch (error) {
    return this
  }
}

Date.prototype.getMonthNumber = function () {
  return (this.getMonth() + 1).padZero()
}
