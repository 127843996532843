export default class ErrorHandler {
  static returnErrorMessage(error) {
    console.log(error);
    if (error.message && error.message === "Network Error") {
      return 'The servers are not reachable at this moment. Please contact support.'
    } else {
      return error.response.data.msg
    }
  }
}
