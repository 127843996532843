<template>
  <fragment>
    <tr
      class="dividerRow"
      v-if="(order && prevOrder && order.week != prevOrder.week) || !prevOrder"
    >
      Week
      {{
        order.week
      }}
    </tr>
    <tr class="tableRow" :id="'orderItemRow' + order.ocr">
      <td
        class="ocrTd"
        :style="statusColor"
        :width="isMobile ? 130 : 180"
        @click="toggleOrder(order.ocr)"
      >
        <md-checkbox
          v-if="order.confirmed === 1"
          style="top: 3px; margin: 0; margin-right: 17px"
          v-model="selectedOrders"
          :value="order.orderId"
          class="md-primary"
        />
        <span style="top: -1px; position: relative">#{{ order.ocr }}</span>
      </td>
      <!-- Pickup date and time -->
      <td @click="toggleOrder(order.ocr)" v-if="!isMobile">
        {{ formatDateWithDayname(new Date(order.datePickupDelivery)) }}
        {{ order.timePickupDelivery
        }}<span v-if="order.timePickupDelivery.length == 2">:00</span>
      </td>
      <!-- Amount products -->
      <td @click="toggleOrder(order.ocr)" v-if="!isMobile">
        {{ order.products.length }}
        {{ order.products.length > 1 ? 'products' : 'product' }}
      </td>
      <!-- Customer name -->
      <td class="customerTd" @click="openCustomer(order.customerId)">
        <span v-if="order.customerId">{{ customerName }}</span>
        <span v-else>{{ order.name }}</span>
        &nbsp;<i
          v-if="hasModule('customers')"
          class="fas fa-external-link-alt"
        ></i>
      </td>
      <td v-if="!isMobile && deliveryMethodSelect != 'hemleverans'">
        {{ order.locationName }}
      </td>
      <!-- <td v-if="order.confirmed === 1 && !isMobile" class="statusTd">
        <StatusSelector :order="order" />
      </td> -->
      <td v-if="order.confirmed === 0">
        <MainButton
          :clickFunction="
            () => {
              confirmOrder(order.orderId)
            }
          "
          styleClass="primary"
          title="Confirm"
          :buttonStyle="{ letterSpacing: '0.5px' }"
        />
      </td>
      <td v-if="order.confirmed === 0">
        <MainButton
          :clickFunction="
            () => {
              rejectOrder(order.orderId)
            }
          "
          title="Reject"
          :buttonStyle="{ letterSpacing: '0.5px' }"
        />
      </td>
      <td class="buttonsTd" v-if="$route.name != 'NewOrders'">
        <div @click="toggleOption(order.orderId)" class="toggleOptionButton">
          <i class="fas fa-ellipsis-v"></i>
        </div>
        <div v-if="isHidden" class="optionMenu">
          <FlexBox>
            <H4 @click.native="editOrder(order.orderId)">Edit</H4>
            <i class="fas fa-edit"></i>
          </FlexBox>
          <FlexBox>
            <H4 @click.native="openPopup()">Comment</H4>
            <i class="fas fa-edit"></i>
          </FlexBox>
        </div>
      </td>
    </tr>
    <Loader v-if="!order" :color="'#444'" />
    <tr class="orderInfo">
      <td colspan="10">
        <div
          class="orderScrollWrapper"
          :id="'openOrderItem' + order.ocr"
          :style="openOrderStyle"
          ref="openOrderItem"
        >
          <OpenOrder :order="order" />
        </div>
      </td>
    </tr>
  </fragment>
</template>

<script>
import { mapGetters } from 'vuex'

import StatusSelector from '../StatusSelector'
import OpenOrder from '../../components/orders/OpenOrder'
import Loader from '../../components/Loader'
import ModuleService from './../../services/moduleService'
import OrderPopup from '../../components/orders/popups/OrderPopup'

import MainButton from '@/layout/MainButton'
import FlexBox from '@/layout/FlexBox.vue'
import H4 from '@/layout/typography/H4.vue'

import { formatDateWithDayname } from '../../globalFunctions/date'

export default {
  components: {
    StatusSelector,
    MainButton,
    OpenOrder,
    Loader,
    OrderPopup,
    FlexBox,
    H4,
  },
  data() {
    return {
      isHidden: false,
      isOpen: false,
      isMounted: false,
    }
  },
  props: {
    order: Object,
    removeOrder: Function,
    deliveryMethodSelect: String,
    selectedOrders: Array,
    prevOrder: Object,
  },
  computed: {
    customerName() {
      if (this.isMobile) {
        let names = this.order.name.split(' ')

        if (names.length > 1) {
          let firstname = this.order.name.split(' ')[0]
          let lastname = this.order.name.split(' ')[1]
          if (this.order.name) {
            return firstname.charAt(0).capitalizeFirstLetter() + '. ' + lastname
          }
        } else {
          return this.order.name.capitalizeFirstLetter()
        }
      } else {
        return this.order.name.capitalizeFirstLetter()
      }
    },
    openOrderStyle() {
      if (this.order && this.order.products && this.isMounted) {
        let el = this.$refs.openOrderItem.querySelector('.orderBox')

        if (el) {
          var height = window.getComputedStyle(el).height
        }

        if (this.isOpen) {
          return { 'max-height': '600px' }
        }
      }
    },
    statusColor() {
      let le = 15
      if (window.innerWidth < 1500) le = 5

      switch (Number(this.order.status)) {
        case 0:
          return { 'border-left': le + 'px solid #c75959' }
        case 1:
          return { 'border-left': le + 'px solid #ffd400' }
        case 2:
          return { 'border-left': le + 'px solid #2eb14a' }
        default:
          break
      }
    },
    ...mapGetters({
      isMobile: 'isMobile',
    }),
  },
  mounted() {
    this.isMounted = true
    if (window.location.hash == '#' + this.order.ocr) {
      this.isOpen = true
    }
  },
  methods: {
    formatDateWithDayname,
    openPopup() {
      this.$store.commit('SET_POPUP', {
        component: OrderPopup,
        properties: { order: this.order },
      })
    },
    addAlert(title) {
      this.$store.commit('ADD_ALERT', {
        type: 'success',
        title,
        time: 5000
      })
    },
    hasModule(name) {
      return ModuleService.hasModule(name)
    },
    confirmOrder(orderId) {
      this.$socket.emit('confirmOrder', { orderId }, (res) => {
        this.removeOrder(orderId)
        this.addAlert(res.msg)
      })
    },
    rejectOrder(orderId) {
      this.$socket.emit('rejectOrder', { orderId }, (res) => {
        this.removeOrder(orderId)
        this.closeOption()
        this.addAlert(res.msg)
      })
    },
    editOrder(data) {
      //Update order to match data
      this.$router.push({ name: 'EditOrder', params: { orderID: data } })
    },
    toggleOption() {
      this.isHidden = !this.isHidden
    },
    closeOption() {
      if (this.isHidden) {
        this.isHidden = false
      }
    },
    toggleOrder() {
      if (this.isOpen) {
        this.isOpen = false
      } else {
        this.isOpen = true
      }
    },
    updateOrderStatus(ocr, newStatus) {
      this.$socket.emit('updateOrderStatus', { ocr, newStatus })
    },
    unslug(string) {
      return (string.charAt(0).toUpperCase() + string.slice(1)).replace(
        '_',
        ' '
      )
    },
    openCustomer(customerId) {
      if (this.hasModule('customers')) {
        this.$router.push({ name: 'OpenCustomer', params: { customerId } })
      } else {
        this.toggleOrder()
      }
    },
  },
}
</script>

<style scoped lang="scss">
.orderScrollWrapper {
  transform-origin: top;
  max-height: 0px;
  transition: max-height 0.5s ease-in-out, box-shadow 0.1s linear;
  background: white;
  display: block;
  overflow: hidden;
  position: relative;
  border-radius: 5px;
  box-shadow: 0px 0px 5px -2px rgba(189, 189, 189, 1);
}

.toggleOptionButton {
  text-align: center;
  padding: 8px;
  display: flex;
  cursor: pointer;
}

.toggleOptionButton i {
  margin: auto;
  color: #666;
}

.tableRow {
  height: 0;
  position: relative;
  transition: 0.2s;
}

.optionMenuIcon {
  font-size: 12pt;
  display: block;
  padding: 0 10px !important;
  margin: 0 !important;
}

.optionMenu {
  position: absolute;
  background: white;
  border-radius: 3px;
  border: 1px solid #eeee;
  right: 75px;
  z-index: 10;
  overflow: hidden;
}

.optionMenu p {
  text-align: left;
  font-size: 9pt;
  padding: 5px 10px;
  cursor: pointer;
  margin: 0;
  border-bottom: 1px solid #eaeaea;
  justify-content: space-between;
  display: flex;
  width: 100px;
  font-weight: lighter;
}

.optionMenu i {
  padding: 0px 10px;
}

.optionMenu p:hover {
  background: #eaeaea;
}

.optionMenu p:last-child {
  border-bottom: none;
}

.ocrTd {
  color: #4c6ba2;
  cursor: pointer;
}

.customerTd {
  cursor: pointer;
  word-break: break-all;
}

.statusTd {
  text-align: right;
  width: 10px;
}

.fa-archive {
  cursor: pointer;
}

.fa-archive:hover {
  color: #444;
}

.hoverInfoBox {
  position: absolute;
  display: none;
  font-weight: lighter;
  background: #eaeaea;
  border: 1px solid;
  border-radius: 5px;
  padding: 2px 5px;
}
</style>
