<template>
  <div :style="displaySidebar" v-if="isLoggedIn" id="sidebar">
    <SidebarDropdownItem
      v-if="verifyPermission([6])"
      @click.native="toggleSidebar()"
      name="Dashboard"
      icon="bar-chart-outline"
    />
    <SidebarDropdownItem
      v-if="verifyPermission([1])"
      @click.native="toggleSidebar()"
      name="Pos"
      icon="apps-outline"
    />
    <SidebarItem icon="pricetags-outline" title="Products">
      <SidebarDropdownItem
        @click.native="toggleSidebar()"
        name="Products"
        icon="pricetags-outline"
        title="All products"
      />
      <SidebarDropdownItem
        @click.native="toggleSidebar()"
        name="Groups"
        icon="apps-outline"
      />
    </SidebarItem>
    <SidebarItem
      icon="document-text-outline"
      title="Orders"
      v-if="hasModule('orders')"
    >
      <router-link
        style="justify-content: space-between"
        @click.native="toggleSidebar()"
        :to="{
          name: 'NewOrders',
        }"
      >
        <span style="display: inherit">
          <ion-icon name="folder-open-outline"></ion-icon>
          New Orders
        </span>
        <div v-if="amountNewOrders" class="amountNewOrdersBox">
          {{ amountNewOrders }}
        </div>
      </router-link>
      <SidebarDropdownItem
        @click.native="toggleSidebar()"
        name="Orders"
        icon="folder-outline"
      />
      <SidebarDropdownItem
        @click.native="toggleSidebar()"
        name="ArchivedOrders"
        title="Archived Orders"
        icon="archive-outline"
      />
      <SidebarDropdownItem
        @click.native="toggleSidebar()"
        name="TotalOrders"
        title="Total"
        icon="reader-outline"
      />
    </SidebarItem>
    <SidebarDropdownItem
      v-if="verifyPermission([1])"
      @click.native="toggleSidebar()"
      name="Customers"
      icon="people-outline"
    />
    <SidebarItem
      icon="cube-outline"
      title="Storage"
      v-if="hasModule('storage')"
    >
      <SidebarDropdownItem
        @click.native="toggleSidebar()"
        name="Inventory"
        icon="file-tray-stacked-outline"
      />
    </SidebarItem>
    <SidebarItem
      icon="construct-outline"
      title="Management"
      v-if="verifyPermission([5]) || verifyPermission([4])"
    >
      <SidebarDropdownItem
        v-if="verifyPermission([4])"
        @click.native="toggleSidebar()"
        name="Employees"
        icon="people-outline"
      />
      <SidebarDropdownItem
        v-if="verifyPermission([5])"
        @click.native="toggleSidebar()"
        name="Roles"
        title="Roles"
        icon="lock-closed-outline"
      />
    </SidebarItem>
    <SidebarItem
      icon="settings-outline"
      title="Settings"
      v-if="hasModule('storage')"
    >
      <SidebarDropdownItem
        @click.native="toggleSidebar()"
        name="Stores"
        icon="storefront-outline"
      />
      <SidebarDropdownItem
        @click.native="toggleSidebar()"
        name="StaticData"
        title="Static Data"
        icon="list-outline"
      />
    </SidebarItem>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import SidebarItem from './sidebar/SidebarItem'

import ModuleService from './../services/moduleService'
import SidebarDropdownItem from './sidebar/SidebarDropdownItem.vue'
import AuthService from '../services/AuthService'

export default {
  props: ['statusSidebar', 'toggleSidebar'],
  components: {
    'ion-icon': null,
    SidebarItem,
    ModuleService,
    SidebarDropdownItem,
  },
  methods: {
    hasModule(name) {
      return ModuleService.hasModule(name)
    },
    verifyPermission(neededPermissions) {
      return AuthService.verifyPermission(neededPermissions)
    }
  },
  data() {
    return {
      amountNewOrders: null,
    }
  },
  sockets: {
    amountNewOrders(data) {
      this.amountNewOrders = data
    },
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
      isMobile: 'isMobile',
    }),
    displaySidebar() {
      if (!this.statusSidebar && this.isMobile) {
        return { transform: 'translateX(-300px)' }
      }
    },
  },
}
</script>

<style lang="scss">
#sidebar {
  position: fixed;
  height: 100%;
  transition: 0.2s;
  z-index: 10;
  display: block;
  top: 50px;
  background: #fcfcfc;
  min-width: 230px;
  height: 100%;
  transition: 0.2s;
  z-index: 10;
  padding: 50px 0 100px 0;
  -webkit-box-shadow: 0px 0px 5px -2px rgba(125, 125, 125, 1);
  -moz-box-shadow: 0px 0px 5px -2px rgba(125, 125, 125, 1);
  box-shadow: 0px 0px 4px -2px rgba(125, 125, 125, 1);
  overflow-y: scroll;
}

#sidebar::-webkit-scrollbar {
  display: none;
}

#sidebar .submenu a {
  padding-left: 40px;
  font-size: 9pt;
}

#sidebar .submenu a ion-icon {
  top: 1px;
}

#sidebar {
  a,
  p {
    user-select: none;
    display: flex;
    padding: 13px 15px;
    padding-left: 30px;
    text-decoration: none;
    color: #111;
    padding-left: 20px;
    border: none;
    width: calc(100%);
    box-sizing: border-box;
    text-align: left;
    font-family: 'AvenirRound', sans-serif;
    font-weight: lighter;
    font-size: 10pt;
    letter-spacing: 1px;
    background: none;
    cursor: pointer;
    border-left: transparent 4px solid;
  }
}

#sidebar .dropdown {
  display: flex;
  justify-content: space-between;
}

.is-active {
  background: rgb(240, 240, 240) !important;
  border-right: 2px solid $accent !important;
}

.is-active ion-icon {
  color: $accent;
}

#sidebar a:hover,
#sidebar button:hover {
  background: rgb(224 224 224);
}

.amountNewOrdersBox {
  background: rgb(59, 59, 59);
  color: white;
  padding: 0px 3px 0px 4px;
  border-radius: 5px;
  font-size: 8pt;
  position: relative;
  top: 0px;
  height: 17px;
  right: 8px;
}

#sidebar {
  i {
    color: #444;
    margin-right: 10px;
    font-size: 10.5pt;
  }
}

#sidebar ion-icon {
  font-size: 12pt;
  top: 3px;
  position: relative;
  color: #444;
  margin-right: 5px;
}

@media screen and (max-width: 700px) {
}
</style>