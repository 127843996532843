<template>
  <textarea rows="10" class="standardTextarea" v-model="inputVal" />
</template>

<script>
export default {
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  }
}
</script>

<style>
.standardTextarea {
  width: 100%;
  border-radius: 5px;
  /* resize: none; */
  padding: 10px;
  border: 1px solid #c0c0c0;
  -webkit-appearance: none;
}
</style>