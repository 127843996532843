<template>
  <router-link :to="{ name }">
    <ion-icon :name="icon"></ion-icon>
    {{ title || name }}
  </router-link>
</template>

<script>
export default {
  props: ['name', 'icon', 'title']
}
</script>

<style>
</style>