import Vue from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueMeta from 'vue-meta'
import SocketIO from 'socket.io-client'
import VueSocketIO from 'vue-socket.io'
import ErrorHandler from './services/ErrorHandler'
import './prototype'
import router from './router'
import store from './vuex/store'

import firebase from 'firebase/app'
import 'firebase/storage'

import Fragment from './components/Fragment'
Vue.use(Fragment.Plugin)

import JsonExcel from 'vue-json-excel'
Vue.component('downloadExcel', JsonExcel)

import './assets/css/index.scss'

import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
Vue.use(VueMaterial)

import VueSlugify from 'vue-slugify'
Vue.use(VueSlugify)

// Context Menu
import ContextMenuDirective from './components/contextMenu/v-context-menu'
import ContextMenu from '@/components/contextMenu/ContextMenu'
Vue.directive('context-menu', ContextMenuDirective)
Vue.component('ContextMenu', ContextMenu)

axios.defaults.headers.common['Authorization'] = `Bearer ${store.state.token}`

if (process.env.NODE_ENV == 'development') {
  axios.defaults.baseURL = 'http://localhost:3001/api/'
} else {
  axios.defaults.baseURL = window.location.origin + '/api/'
}

axios.interceptors.response.use(
  function(response) {
    return response
  },
  function(error) {
    if (error.response.status.toString().substring(0, 1) === '5') {
      store.commit('ADD_ALERT', {
        type: 'error',
        title: 'Something went wrong!',
        description: error.response.data.msg,
        time: 4000
      })
    }
    return Promise.reject(error)
  }
)

const firebaseConfig = {
  apiKey: 'AIzaSyCttpBoOy6ueCJ1tsyOZYNJJLJIFD01INM',
  authDomain: 'fiskskaldjur-1027c.firebaseapp.com',
  databaseURL: 'https://fiskskaldjur-1027c.firebaseio.com',
  projectId: 'fiskskaldjur-1027c',
  storageBucket: 'fiskskaldjur-1027c.appspot.com',
  messagingSenderId: '742710166559',
  appId: '1:742710166559:web:40f31a09a0d7d56d8f412a',
  measurementId: 'G-MGHTZ4XWHY'
}

firebase.initializeApp(firebaseConfig)

Vue.config.ignoredElements = ['ion-icon']

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})

Vue.use(VueAxios, axios)

Vue.config.productionTip = false

export const initSocketConnection = () => {
  var socketIOUrlString =
    process.env.NODE_ENV == 'development'
      ? 'http://localhost:3002'
      : window.location.origin

  if (store.state.token) {
    socketIOUrlString += `/?token=${store.state.token}`

    const socketConnection = new VueSocketIO({
      debug: false,
      connection: socketIOUrlString,
      transports: ['websocket'],
      options: {
        withCredentials: true
      }
    })

    
    Vue.use(socketConnection)
  }
}

initSocketConnection()

let vueApp = new Vue({
  router,
  store,
  render: h => h(App)
})

export function validateSession() {
  if (store.state.token) {
    axios
      .post('auth/checkIfValidSession/')
      .then(response => {
        const user = {
          username: response.data.username,
          id: response.data.userId
        }

        initSocketConnection()

        vueApp.$socket.emit(
          'joinCompanyRoom',
          vueApp.$store.state.selectedUser.companyId
        )

        let tempUser = JSON.parse(JSON.stringify(store.getters.getUser))
        store.commit('SET_IS_LOGGED_IN', true)
        if (store.state.selectedUser == {}) {
          store.commit('SET_SELECTED_USER', Object.assign(tempUser, user))
        }

        axios
          .post('company/getModules')
          .then(function(response) {
            store.commit('SET_COMPANY_MODULES', response.data)
          })
          .catch(function(error) {
            console.log(ErrorHandler.returnErrorMessage(error))
          })

        axios
          .get('employees/getPermissions')
          .then(function(res) {
            let tempUser = JSON.parse(JSON.stringify(store.getters.getUser))
            store.commit(
              'SET_SELECTED_USER',
              Object.assign(tempUser, { userPermissions: res.data })
            )
          })
          .catch(function(error) {
            console.log(ErrorHandler.returnErrorMessage(error))
          })

        vueApp.$mount('#app')
      })
      .catch(error => {
        console.log(`error`, error)
        console.log(ErrorHandler.returnErrorMessage(error))
        store.commit('SET_IS_LOGGED_IN', false)
        store.dispatch('logout')
        vueApp.$mount('#app')
      })
  } else {
    store.commit('RESET')

    vueApp.$mount('#app')
  }
}

validateSession()
