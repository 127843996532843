<template>
  <div class="popupBox">
    <H4>Order comment</H4>
    <H6>This allows you to add a comment to an order that everyone can see!</H6>
      <StandardTextarea
        placeholder="Here you can add a comment to an order that everyone will see!"
        :value.prop="order.comment"
        v-model="order.comment"
      ></StandardTextarea>
    <BR amount="10" />
    <FlexBox>
      <MainButton
        :clickFunction="updateOrder"
        title="Update"
        styleClass="primary"
      />
      <MainButton
        :clickFunction="closePopup"
        title="Cancel"
        styleClass="secondary"
      />
    </FlexBox>
  </div>
</template>

<script>
import MainButton from '@/layout/MainButton.vue'
import FlexBox from '@/layout/FlexBox.vue'
import StandardTextarea from '@/layout/StandardTextarea.vue'
import H6 from '@/layout/typography/H6.vue'
import H4 from '@/layout/typography/H4.vue'
import BR from '@/layout/linebreak/BR.vue'
import axios from 'axios'

export default {
  components: { MainButton, FlexBox, StandardTextarea, H6, H4, BR },
  props: ['order'],
  data() {
    return {
      commentOld: JSON.parse(JSON.stringify(this.order.comment)),
      fieldType: undefined
    }
  },
  methods: {
    updateOrder() {
      //Update order
      axios
        .post('orders/updateComment', {
          companyId: this.$store.state.selectedUser.companyId,
          order: this.order,
          customerId: this.$route.params.customerId
        })
        .then(res => {
          this.closePopup()
        })
    },
    closePopup() {
      this.order.comment = this.commentOld
      this.$store.commit('CLEAR_POPUP')
    }
  }
}
</script>

<style scoped src="@/assets/css/popup.css">
.product {
  background: red !important;
}
.products {
  width: 200px;
  background: gray;
}
</style>
