<template>
  <div class="wrapper">
    <div class="content">
      <h2 v-if="!this.$store.state.company">Welcome</h2>
      <h2 v-else>{{ this.$store.state.company.companyName }}</h2>
      <br>
      <form
        class="form"
        @submit="connectToCompany"
        v-if="!this.$store.state.company"
      >
        <input type="text" v-model="companyId" placeholder="Company ID" />
        <input
          type="submit"
          :value="companySelectButtonValue"
          :style="companySelectButtonColor"
        />
      </form>
      <form v-else class="form" @submit="login">
        <input type="text" v-model="username" name="username" placeholder="Username" />
        <input type="password" v-model="password" name="password" placeholder="Password" />
        <input style="display:none" type="submit" value="Sign in" />
        <input type="submit" value="Sign in" />
        <p class="changeCompany" @click="removeCompany">Change company</p>
      </form>
      <div v-if="errorMessage" class="errorMessage">
        {{ errorMessage }}
      </div>
    </div>
  </div>
</template>

<script>
import AuthService from './../services/AuthService'
import ErrorHandler from './../services/ErrorHandler'

export default {
  metaInfo: {
    title: 'Login',
    description: 'Aviliax - Order Management system',
    keywords: 'Aviliax - Order Management system',
    titleTemplate: null,
  },
  created() {
    if (this.$store.getters.isLoggedIn) {
      this.$router.push({
        name: 'Orders',
        params: { companyId: this.$store.state.selectedUser.companyId },
      })
    }

    if (process.env.NODE_ENV == 'development') {
      this.companyId = '817d63b0-891e-4517-a9a2-5e72b7a182d2'
    }
  },
  data() {
    return {
      username: '',
      companyId: '',
      password: '',
      errorMessage: null,
      companySelectButtonValue: 'Enter company ID',
      foundCompany: null,
    }
  },
  watch: {
    async companyId(id) {
      const response = await AuthService.getCompanyInfo(id)
      if (response) {
        this.companySelectButtonValue = 'Connect to ' + response.companyName
      } else {
        this.companySelectButtonValue = 'Enter company ID'
      }
      this.foundCompany = response
    },
  },
  computed: {
    companySelectButtonColor() {
      if (this.foundCompany) {
        return { background: 'green' }
      } else {
        return { background: 'red' }
      }
    },
  },
  methods: {
    removeCompany() {
      this.$store.commit('SET_COMPANY', null)
    },
    async connectToCompany(e) {
      e.preventDefault()
      this.$store.commit('SET_COMPANY', this.foundCompany)
    },
    async login(e) {
      e.preventDefault()
      try {
        const credentials = {
          username: this.username,
          companyId: this.$store.state.company.companyId,
          password: this.password,
        }
        const response = await AuthService.login(credentials)
        const user = response
        this.$store.dispatch('login', { user })
        this.$router.push({
          name: 'Orders',
          params: { companyId: this.$store.state.selectedUser.companyId },
        })
      } catch (error) {
        this.errorMessage = ErrorHandler.returnErrorMessage(error)
      }
    },
  },
}
</script>

<style scoped lang="scss">
.wrapper {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
}

.content {
  width: 350px;
  height: auto;
  text-align: center;
  margin: auto;
  padding: 20px;
  border-radius: 10px;
}

.content h2 {
  font-family: 'AvenirRound';
  font-size: 20pt;
  font-weight: lighter;
}

.changeCompany {
  font-family: 'AvenirRound';
  font-weight: lighter;
  text-decoration: underline;
  cursor: pointer;
}

.errorMessage {
  padding: 5px 15px;
  border: solid 1px red;
  border-radius: 5px;
  font-family: 'AvenirRound';
  font-weight: lighter;
  width: calc(100% - 20px);
  margin: 10px;
}

.form {
  display: grid;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px rgb(241, 241, 241) inset !important;
}
.form input {
  background: none;
  margin: 10px;
  font-size: 10pt;
  border: none;
  padding: 10px;
  transition: 0.3s ease-in-out;
  border-bottom: 1px solid #cecece;
}

.form input:focus {
  border-bottom: 1px solid #478ccc;
}

.form input[type='submit'] {
  background: #478ccc;
  color: white;
  padding: 10px 0;
  border-radius: 5px;
  text-transform: uppercase;
  font-family: 'AvenirRound';
  cursor: pointer;
}

.form input[type='submit']:hover {
  background: #4178ac;
}
</style>