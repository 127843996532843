<template>
  <fragment>
    <div id="app" :style="appStyle">
      <TopBar v-if="isLoggedIn" />
      <Sidebar
        v-if="isLoggedIn"
        :statusSidebar="statusSidebar"
        :toggleSidebar="toggleSidebar"
      />
      <div style="width: 100%">
        <main :style="sidebarPadding">
          <router-view />
        </main>
        <MobileNavbar
          v-if="isMobile && isLoggedIn"
          :toggleSidebar="toggleSidebar"
        />
      </div>
    </div>
    <p></p>
    <div
      @click.self="clearPopup()"
      v-if="this.$store.state.popup"
      class="popupWrapper"
    >
      <PopupComponent
        v-bind="{ ...this.$store.state.popup.properties }"
        :is="this.$store.state.popup.component"
      />
    </div>
    <div class="alertWrapper">
      <AlertBox
        :alert="alert"
        :index="index"
        v-for="(alert, index) in this.$store.state.alerts"
        :key="index"
      />
    </div>
  </fragment>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import Sidebar from './components/Sidebar'
import MobileNavbar from './components/MobileNavbar'
import TopBar from './components/TopBar'
import AlertBox from './components/AlertBox'

export default {
  metaInfo: {
    title: 'Aviliax - Order Management system',
    description: 'Aviliax - Order Management system',
    keywords: 'Aviliax - Order Management system',
    titleTemplate: null
  },
  components: {
    Sidebar,
    MobileNavbar,
    TopBar,
    AlertBox
  },
  data() {
    return {
      statusSidebar: false
    }
  },
  methods: {
    toggleSidebar() {
      this.statusSidebar = !this.statusSidebar
    },
    leaving() {
      if (this.isLoggedIn) {
        //Remove socket connection
      }
    },
    clearPopup() {
      this.$store.commit('CLEAR_POPUP')
    }
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
      isMobile: 'isMobile',
      getAlerts: 'getAlerts'
    }),
    sidebarPadding() {
      if (this.isLoggedIn && !this.isMobile)
        return { 'padding-left': 'calc(260px + 30px)' }
      else return { 'padding-top': '50px' }
    },
    appStyle() {
      if (this.isMobile && this.$route.name != 'Login') {
        return { 'padding-bottom': '100px', 'padding-top': '100px' }
      }
    }
  },
  async created() {
    this.$store.commit('CLEAR_POPUP')
    if (this.isLoggedIn) {
      this.$socket.emit(
        'joinCompanyRoom',
        this.$store.state.selectedUser.companyId
      )
      this.$socket.emit('initConnection', this.$store.state.selectedUser.id)
    }
    window.addEventListener('beforeunload', this.leaving)
  }
}
</script>

<style>
@font-face {
  font-family: Avenir;
  src: url('./assets/fonts/Avenir.otf');
}

@font-face {
  font-family: AvenirRound;
  src: url('./assets/fonts/AvenirRound.ttf');
}

@import url('https://unpkg.com/mathlive@0.27.4/dist/mathlive.css');
@import url('https://unpkg.com/mathlive@0.27.4/dist/mathlive.core.css');

@import url('https://use.fontawesome.com/releases/v5.2.0/css/all.css');
@import url('https://fonts.googleapis.com/css2?family=Spartan:wght@300;400;550&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;600;700&display=swap');

* {
  margin: 0;
  outline: none !important;
}

.alertWrapper {
  position: absolute;
  z-index: 20;
  right: 0;
  top: 70px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}
body {
  width: 100%;
  background: rgb(241, 241, 241);
  overflow-x: hidden;
}

#app {
  display: inline;
  width: 100%;
}

main {
  padding-right: 30px;
  width: 100%;
}

.popupWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(161, 161, 161, 0.1);
  z-index: 12;
  backdrop-filter: blur(2px);
  display: flex;
}

@media screen and (max-width: 700px) {
  main {
    padding-right: 0;
  }
}
</style>
