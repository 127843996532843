// 2000-01-01
export function formatDate(date) {
  date = new Date(date)
  return `${date.getFullYear()}-${date.getMonthNumber()}-${date.getDate().padZero()}`
}

// 2021-02-12 17:00
export function formatDateWithTime(date) {
  date = new Date(date)
  return `${date.getFullYear()}-${date.getMonthNumber()}-${('0' + date.getDate()).slice(-2)} ${date.getHours()}:${date.getMinutes().padZero()}`
}

// Wednesday, 19 Aug 09:00
export function formatDateWithDayname(date) {
  date = new Date(date)
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  return `${days[date.getDay()]}, ${('0' + date.getDate()).slice(-2)} ${monthNames[date.getMonth()]} `
}

// 30 Aug 21:01
export function formatDateWithMonthNameAndTime(date) {
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  return `${('0' + date.getDate()).slice(-2)} ${monthNames[date.getMonth()]} ${date.getHours().padZero()}:${date.getMinutes().padZero()}`
}

// Fri, 24 Sep
export function formatDateWithDayAndMonthName(date) {
  date = new Date(date)
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',]
  return `${days[date.getDay()]}, ${date.getDate().padZero()} ${monthNames[date.getMonth()]} `
}