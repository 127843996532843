<template>
  <div class="dropdownWrapper" click.prevent>
    <div class="dropdownBar" @click="isOpen = !isOpen">
      {{ title }}
      <ion-icon name="chevron-down-outline" class="dropdownChevron" />
    </div>
    <div class="dropdownContent" v-if="isOpen">
      <div class="dropdownItem" v-for="(item, i) in items" :key="i">
        <md-checkbox
          v-model="selectedItem"
          :value="item.value"
          :id="'item' + unique + item.value"
        />
        <label :for="'item' + unique + item.value">
          {{ item.title }}
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['items', 'value', 'title'],
  data() {
    return {
      shops: null,
      isOpen: false,
      unique: undefined,
    }
  },
  created() {
    this.unique = this.generateRndString()
  },
  computed: {
    selectedItem: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  methods: {
    generateRndString() {
      let text = ''
      let chars = 'ABCDEFGHIJKLMNOP0123456789'
      for (let i = 0; i < 5; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length))
      }
      return text
    },
  },
}
</script>

<style lang="scss" scoped>
.dropdownWrapper {
  display: inline-flex;
  user-select: none;
}

.dropdownBar {
  display: inline-block;
  font: 400 13.3333px Arial;
  font-size: 10pt;
  padding: 5px 10px 6px 10px;
  border-radius: 5px;
  background: white;
  margin-right: 10px;
  box-shadow: inset 0px 0px 2px 1px rgb(219 219 219);
  border: none;
  align-items: center;
  display: inline-flex;
  cursor: context-menu;
  user-select: none;
}

.dropdownChevron {
  font-size: 12pt;
  margin-left: 10px;
  position: relative;
  color: black;
}

.dropdownContent {
  font-size: 10pt;
  padding: 10px;
  border-radius: 5px;
  margin-right: 10px;
  -webkit-box-shadow: inset 0px 0px 2px 1px rgba(219, 219, 219, 1);
  -moz-box-shadow: inset 0px 0px 2px 1px rgba(219, 219, 219, 1);
  box-shadow: inset 0px 0px 2px 1px rgba(219, 219, 219, 1);
  border: none;
  position: absolute;
  z-index: 10;
  background: white;
  margin-top: 30px;
}

.dropdownItem {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.dropdownItem label {
  cursor: pointer;
}

::v-deep .md-checkbox {
  margin: 5px;
}
::v-deep .md-checkbox .md-checkbox-container:before,
.md-checkbox .md-checkbox-container .md-ripple {
  height: 20px !important;
  width: 20px !important;
}
</style>
