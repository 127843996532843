<template>
  <div v-if="order" class="orderBox">
    <div>
      <div>
        <MainButton
          v-if="order.archived == 0 && order.status === 0"
          :clickFunction="startPacking"
          title="Start packing"
          style="margin: 15px"
        />
      </div>
      <table class="orderProductsTable">
        <tr class="standardTableHeader">
          <th width="30" v-if="order.status === 1 && order.archived == 0"></th>
          <th>Product</th>
          <th width="60" style="">Amount</th>
        </tr>
        <tr
          class="orderProductsTableRow"
          :key="i"
          v-for="(product, i) in order.products"
        >
          <td v-if="order.status === 1 && order.archived == 0">
            <label class="labelCheckbox">
              {{ product.productId }}
              <input
                type="checkbox"
                :checked="product.status === 1"
                @change="updateProductPackedStatus($event, product.id)"
              />
              <span></span>
            </label>
          </td>
          <!-- Product -->
          <td style="word-wrap: break-word">
            {{
              isMobile
                ? shortenWord(product.productName, 25)
                : product.productName
            }}
          </td>
          <!-- Amount in order -->
          <td style="white-space: nowrap">
            {{ product.amount + ' ' + product.unit }}
          </td>
        </tr>
      </table>

      <div>
        <MainButton
          v-if="
            allIsChecked &&
            order.status !== 0 &&
            order.status !== 2 &&
            order.archived == 0
          "
          :clickFunction="donePacking"
          title="Packing complete"
          styleClass="primary"
          :buttonStyle="{ marginLeft: '20px' }"
        />
      </div>
    </div>

    <div>
      <div class="doubleBox">
        <div v-if="order" class="itemBox">
          <h4>Pickup date</h4>
          <h3>
            {{ formatDate(order.datePickupDelivery) }}
            {{ order.timePickupDelivery
            }}<span v-if="order.timePickupDelivery.length == 2">:00</span>
          </h3>
        </div>
        <div class="itemBox">
          <h4>Other info</h4>
          <h3 v-if="order.otherInfo">
            {{ order.otherInfo }}
          </h3>
          <h3 v-else>N/A</h3>
        </div>
        <div class="itemBox">
          <h4>Comment</h4>
          <h3 v-if="order.comment">
            {{ order.comment }}
          </h3>
          <h3 v-else>N/A</h3>
        </div>
      </div>
      <span v-if="showMore">
        <div class="doubleBox">
          <div class="itemBox">
            <h4>Customer</h4>
            <h3 v-if="order.name">{{ order.name.capitalizeFirstLetter() }}</h3>
          </div>
          <div class="itemBox">
            <h4>Address</h4>
            <h3 v-if="order.address">
              {{ order.address.capitalizeFirstLetter() }}
            </h3>
            <h3 v-else>No customer address</h3>
          </div>
        </div>
        <div class="doubleBox">
          <div v-if="order" class="itemBox">
            <h4>Payment</h4>
            <h3>{{ order.paymentMethod }}</h3>
          </div>
          <div class="itemBox">
            <h4>Packers</h4>
            <h3>
              <fragment v-if="order.packers.length > 0">
                <p :key="packer.id" v-for="packer in order.packers">
                  {{ packer }}
                </p>
              </fragment>
              <p v-else>No packers</p>
            </h3>
          </div>
        </div>
        <div class="doubleBox">
          <div v-if="order" class="itemBox">
            <h4>Creator</h4>
            <h3>{{ order.username || 'From webshop' }}</h3>
          </div>
          <div v-if="order" class="itemBox">
            <h4>Date added</h4>
            <h3>{{ formatDateWithTime(order.dateAdded) }}</h3>
          </div>
          <div v-if="order" class="itemBox">
            <h4>Delivery</h4>
            <h3>{{ unslug(order.deliveryMethod) }}</h3>
          </div>
        </div>
      </span>
      <div class="doubleBox">
        <div v-if="order" class="itemBox">
          <p @click="showMore = !showMore" class="showMoreButton">
            {{ !showMore ? 'Show more' : 'Show less' }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MainButton from '@/layout/MainButton'
import { mapGetters } from 'vuex'
import { formatDate, formatDateWithTime } from '../../globalFunctions/date'

export default {
  props: {
    order: Object,
  },
  components: {
    MainButton,
  },
  data() {
    return {
      setOffDisabled: false,
      showMore: false,
    }
  },
  computed: {
    allIsChecked() {
      return this.checkIfAllIsChecked(this.order.products)
    },
    ...mapGetters({
      isMobile: 'isMobile',
    }),
  },
  methods: {
    formatDate,
    formatDateWithTime,
    shortenWord(word, maxLetters) {
      if (word.length <= maxLetters) {
        return word
      }
      return word.substring(0, maxLetters) + '...'
    },
    openBatch(batchNumber) {
      this.$router.push({ name: 'Batch', params: { batchId: batchNumber } })
    },
    checkIfAllIsChecked(arr) {
      if (arr.find((product) => product.status === 0)) {
        return false
      } else {
        return true
      }
    },
    updateProductPackedStatus(event, productId) {
      this.$socket.emit('updateProductPackedStatus', {
        packerId: this.$store.state.selectedUser.id,
        checked: event.target.checked,
        productId,
        orderId: this.order.orderId,
      })

      if (event.target.checked) {
        this.order.products[
          this.order.products.findIndex((item) => item.id === productId)
        ].status = 1
      } else {
        this.order.products[
          this.order.products.findIndex((item) => item.id === productId)
        ].status = 0
      }
    },
    donePacking() {
      this.$socket.emit(
        'donePacking',
        {
          orderId: this.order.orderId,
          packerId: this.$store.state.selectedUser.id,
        },
        (res) => {
          this.order.status = 2
        }
      )
    },
    unslug(string) {
      return (
        String(string).charAt(0).toUpperCase() + String(string).slice(1)
      ).replace('_', ' ')
    },
    startPacking() {
      this.$socket.emit('changeOrderStatus', {
        status: 1,
        orderId: this.order.orderId,
      })
    },
  },
}
</script>

<style scoped lang="scss">
.textBox p {
  font-family: 'Avenir';
  color: #555;
  margin: 0;
}

.orderProductsTable {
  font-family: 'Avenir';
  width: auto;
  padding: 20px;
  border-collapse: separate;
  border-spacing: 0 5px;
  table-layout: fixed;
}

.orderProductsTableRow {
  background: white;
  height: 50px;
  text-align: left;
}

.disabled {
  cursor: not-allowed;
  color: #ccc;
}

.showMoreButton {
  font-size: 10pt;
  text-decoration: underline;
  color: rgb(19, 75, 179);
  cursor: pointer;
}

.openBatchNumber {
  cursor: pointer;
  transition: 0.2s;
}

.openBatchNumber:hover {
  color: rgb(0, 0, 124);
}

.orderProductsTableRow td {
  padding-right: 20px;
  border-top: 1px solid #eee;
  font-size: 10pt;
}

.standardTableHeader th {
  padding-left: 0px !important;
  padding-right: 10px;
}

.labelCheckbox {
  margin: 0;
}

.orderBox {
  display: flex;
  padding: 15px 10px;
  max-height: 600px;
  overflow-y: auto;
}
.doubleBox {
  display: flex;
  margin: 10px;
}

.otherInfoBox {
  margin: 20px;
}

.itemBox {
  margin: 5px 10px;
}

h3 {
  font-family: 'Avenir';
  margin: 5px 0;
  font-weight: lighter;
  font-size: 11pt;
  color: rgb(77, 84, 90);
}

h4 {
  color: rgb(170, 170, 170);
  font-weight: lighter;
  font-family: 'Avenir';
  margin: 5px 0;
  font-size: 9pt;
}

.packingReadyButton {
  padding: 5px 10px;
  border-radius: 6px;
  border: none;
  font-size: 10pt;
  background: green;
  color: white;
  cursor: pointer;
}

.packingReadyButton:hover {
  background: rgb(6, 139, 6);
}

.startPackingButton {
  padding: 5px 10px;
  border-radius: 6px;
  border: none;
  font-size: 10pt;
  background: rgb(204, 65, 65);
  color: white;
  cursor: pointer;
}

.startPackingButton:hover {
  background: rgb(185, 71, 71);
}

.currentlyPacking {
  padding: 5px 7px;
  border-radius: 6px;
  border: none;
  font-size: 10pt;
  font-family: 'Avenir';
  background: #da9008de;
  color: white;
}

@media screen and (max-width: 900px) {
  .orderProductsTable {
    width: 100%;
  }

  .orderBox {
    display: block !important;
  }
  h3 {
    font-size: 10pt !important;
  }
  h4 {
    font-size: 8pt !important;
  }

  .itemBox {
    margin: 0 15px;
  }
}
</style>
