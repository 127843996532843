var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"bulkMenuWrapper"},[(_vm.orders && _vm.orders.length > 0 && _vm.displayType != 'all')?_c('div',{staticClass:"bulkMenu",style:(_vm.bulkMenuBottom)},[_c('div',{staticStyle:{"display":"flex"}},[_c('span',{staticStyle:{"margin":"0 15px","font-family":"'Spartan'","font-size":"9pt"}},[_c('span',{staticStyle:{"background":"#444","padding":"4px 6px 3px 6px","border-radius":"5px","font-size":"9pt"}},[_vm._v(_vm._s(_vm.selectedOrders.length))]),_vm._v("  "),(!_vm.isMobile)?_c('fragment',[_vm._v("orders")]):_vm._e(),_vm._v(" selected")],1)]),_c('div',[_c('MainButton',{attrs:{"disabled":_vm.selectedOrders.length > 0 ? false : true,"clickFunction":_vm.bulkArchive,"title":"Mark as delivered","buttonStyle":_vm.bulkMenuButtonStyle,"icon":'fas fa-truck'}})],1)]):_vm._e()]),_c('div',{staticClass:"pageWrapper"},[_c('div',{staticClass:"topbar"},[_c('div',{staticStyle:{"display":"flex"}},[_c('H2',[_vm._v("Orders")])],1),(!this.$route.params.customerId)?_c('MainButton',{attrs:{"clickFunction":function () {
            _vm.$router.push({
              name: 'AddOrder',
              params: {
                currentPage: 1,
              },
            })
          },"title":!this.$store.state.addOrderInformation
            ? 'Add order'
            : 'Continue to add order',"styleClass":"primary","buttonStyle":{ marginLeft: '15px' }}}):_vm._e()],1),_c('SearchInput',{attrs:{"placeholder":"Search"},model:{value:(_vm.searchString),callback:function ($$v) {_vm.searchString=$$v},expression:"searchString"}}),_c('br'),_c('div',{staticStyle:{"margin-top":"10px"}},[_c('md-checkbox',{staticClass:"md-primary",staticStyle:{"top":"5px","margin":"0","margin-right":"10px","border-color":"black"},on:{"change":_vm.changeBulkSelect},model:{value:(_vm.bulkSelect),callback:function ($$v) {_vm.bulkSelect=$$v},expression:"bulkSelect"}}),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.deliveryMethodSelect),expression:"deliveryMethodSelect"}],staticClass:"dropDownSelect",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.deliveryMethodSelect=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{domProps:{"value":null}},[_vm._v("All")]),_c('option',{attrs:{"value":"hemleverans"}},[_vm._v("Hemleverans")]),_c('option',{attrs:{"value":"drive_through"}},[_vm._v("Drive through")]),_c('option',{attrs:{"value":"in_store"}},[_vm._v("In store")])]),_c('DropdownSelect',{attrs:{"title":"Select packed status","items":[
          { title: 'Packed', value: 2 },
          { title: 'Packing', value: 1 },
          { title: 'Not started', value: 0 } ]},model:{value:(_vm.packedStatus),callback:function ($$v) {_vm.packedStatus=$$v},expression:"packedStatus"}}),_c('ShopPicker'),_c('DatePicker',{attrs:{"value-type":"MM-DD-YYYY","range":true,"default-value":Date.now(),"range-separator":' to ',"placeholder":"Specify the date"},on:{"clear":_vm.clearDateRange},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}})],1),(
        _vm.selectedOrders.length > 0 &&
        !(_vm.orderIds.length == _vm.selectedOrders.length) &&
        _vm.orders.length == _vm.selectedOrders.length
      )?_c('div',{staticClass:"bulkSelectBox"},[_vm._v(" Alla "),_c('b',[_vm._v(_vm._s(_vm.selectedOrders.length))]),_vm._v(" ordrar på den här sidan har markerats. "),_c('span',{on:{"click":_vm.handleSelectAllOrders}},[_vm._v("Markera alla "),_c('b',[_vm._v(_vm._s(_vm.orderIds.length))]),_vm._v(" ordrar.")])]):_vm._e(),(
        _vm.selectedOrders.length > 0 && _vm.orderIds.length == _vm.selectedOrders.length
      )?_c('div',{staticClass:"bulkSelectBox"},[_vm._v(" Alla "),_c('b',[_vm._v(_vm._s(_vm.selectedOrders.length))]),_vm._v(" ordrar har markerats. ")]):_vm._e(),_c('fragment',[_c('table',{staticClass:"standardTable"},[_c('tr',{staticClass:"standardTableHeader"},[_c('th',[_c('span',{staticStyle:{"left":"50px","position":"relative"}},[_vm._v("Order")])]),(!_vm.isMobile)?_c('th',[_vm._v("Pickup date")]):_vm._e(),(!_vm.isMobile)?_c('th',[_vm._v("Amount products")]):_vm._e(),_c('th',[_vm._v("Customer")]),(!_vm.isMobile && _vm.deliveryMethodSelect != 'hemleverans')?_c('th',[_vm._v(" Store ")]):_vm._e()]),(!_vm.orders)?_c('br'):_vm._e(),_c('LoadingRowTable',{attrs:{"height":18,"dataList":_vm.orders,"columnsAmount":6}},[_c('fragment',_vm._l((_vm.sortedOrders),function(order,i){return _c('OrderItem',{key:i,attrs:{"deliveryMethodSelect":_vm.deliveryMethodSelect,"prevOrder":_vm.sortedOrders[i - 1],"order":order,"removeOrder":_vm.removeOrder,"selectedOrders":_vm.selectedOrders}})}),1)],1)],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }