<template>
  <span>
    <button
      @keydown.left="goLeft()"
      :tabindex="tabIndex"
      @click="execute"
      @mouseover="hover = true"
      @mouseleave="hover = false"
      :style="[buttonStyle, hoverColor]"
      :class="[styleClass, disabled ? 'disabled' : '']"
    >
      <i v-if="icon" :class="icon + ' icon'"></i>
      {{ this.title }}
    </button>
  </span>
</template>
        

<script>
export default {
  props: ['data', 'tabIndex', 'title', 'icon', 'disabled', 'buttonStyle', 'styleClass', 'clickFunction'],
  data() {
    return {
      hover: false,
    }
  },
  computed: {
    hoverColor() {
      if (this.hover) {
        return { filter: 'grayscale(40%)' }
      }
    }
  },
  methods: {
    execute() {
      if (!this.disabled) {
        this.clickFunction()
      }
    }
  }
}
</script>

<style scoped lang="scss">
button {
  display: inline-block;
  height: 100%;
  padding: 4px 14px;
  padding-top: 6px;
  background: #585b5e;
  border: 2px solid #585b5e;
  border-radius: 8px;
  color: white;
  font-family: 'Spartan';
  font-weight: 550;
  font-size: 9pt;
  transform: translateY(-2px);
  -webkit-box-shadow: 0px 0px 3px 0px rgb(175, 175, 175);
  -moz-box-shadow: 0px 0px 3px 0px rgb(175, 175, 175);
  box-shadow: 0px 0px 3px 0px rgb(175, 175, 175);
  cursor: pointer;
  letter-spacing: 0.5px;
  transition: 0.1s;
  user-select: none;
}

.default {
  background: white;
  color: $accent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.primary {
  background: $accent;
  border: 2px solid $accent;
}

.secondary {
  background: transparent;
  border: 2px solid $accent;
  color: $accent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.secondary:hover {
  background: transparent;
  border: 2px solid $accent;
  background: $accent;
  color: white;
}

.disabled {
  background: #73988a;
  cursor: not-allowed;
  opacity: 0.5;
}

button:focus {
  border: 1px solid black !important;
}
</style>