<template>
  <div>
    <div class="bulkMenuWrapper">
      <div
        class="bulkMenu"
        :style="bulkMenuBottom"
        v-if="orders && orders.length > 0 && displayType != 'all'"
      >
        <div style="display: flex">
          <span style="margin: 0 15px; font-family: 'Spartan'; font-size: 9pt">
            <span
              style="
                background: #444;
                padding: 4px 6px 3px 6px;
                border-radius: 5px;
                font-size: 9pt;
              "
              >{{ selectedOrders.length }}</span
            >
            &nbsp;<fragment v-if="!isMobile">orders</fragment> selected</span
          >
        </div>
        <div>
          <MainButton
            :disabled="selectedOrders.length > 0 ? false : true"
            :clickFunction="bulkArchive"
            title="Mark as delivered"
            :buttonStyle="bulkMenuButtonStyle"
            :icon="'fas fa-truck'"
          />
        </div>
      </div>
    </div>
    <div class="pageWrapper">
      <div class="topbar">
        <div style="display: flex">
          <H2>Orders</H2>
        </div>
        <MainButton
          v-if="!this.$route.params.customerId"
          :clickFunction="
            () => {
              $router.push({
                name: 'AddOrder',
                params: {
                  currentPage: 1,
                },
              })
            }
          "
          :title="
            !this.$store.state.addOrderInformation
              ? 'Add order'
              : 'Continue to add order'
          "
          styleClass="primary"
          :buttonStyle="{ marginLeft: '15px' }"
        />
      </div>
      <SearchInput placeholder="Search" v-model="searchString" />
      <br />
      <div style="margin-top: 10px">
        <md-checkbox
          style="top: 5px; margin: 0; margin-right: 10px; border-color: black"
          v-model="bulkSelect"
          class="md-primary"
          @change="changeBulkSelect"
        />
        <select class="dropDownSelect" v-model="deliveryMethodSelect">
          <option :value="null">All</option>
          <option value="hemleverans">Hemleverans</option>
          <option value="drive_through">Drive through</option>
          <option value="in_store">In store</option>
        </select>
        <DropdownSelect
          v-model="packedStatus"
          title="Select packed status"
          :items="[
            { title: 'Packed', value: 2 },
            { title: 'Packing', value: 1 },
            { title: 'Not started', value: 0 },
          ]"
        />
        <!-- SHOP PICKER -->
        <ShopPicker />
        <DatePicker
          v-model="dateRange"
          value-type="MM-DD-YYYY"
          :range="true"
          :default-value="Date.now()"
          :range-separator="' to '"
          @clear="clearDateRange"
          placeholder="Specify the date"
        ></DatePicker>
      </div>
      <div
        class="bulkSelectBox"
        v-if="
          selectedOrders.length > 0 &&
          !(orderIds.length == selectedOrders.length) &&
          orders.length == selectedOrders.length
        "
      >
        Alla <b>{{ selectedOrders.length }}</b> ordrar på den här sidan har
        markerats.
        <span @click="handleSelectAllOrders"
          >Markera alla <b>{{ orderIds.length }}</b> ordrar.</span
        >
      </div>
      <div
        class="bulkSelectBox"
        v-if="
          selectedOrders.length > 0 && orderIds.length == selectedOrders.length
        "
      >
        Alla <b>{{ selectedOrders.length }}</b> ordrar har markerats.
      </div>
      <fragment>
        <table class="standardTable">
          <tr class="standardTableHeader">
            <th><span style="left: 50px; position: relative">Order</span></th>
            <th v-if="!isMobile">Pickup date</th>
            <th v-if="!isMobile">Amount products</th>
            <th>Customer</th>
            <!-- <th v-if="!isMobile">Total</th> -->
            <th v-if="!isMobile && deliveryMethodSelect != 'hemleverans'">
              Store
            </th>
          </tr>
          <br v-if="!orders" />
          <LoadingRowTable :height="18" :dataList="orders" :columnsAmount="6">
            <fragment>
              <OrderItem
                :deliveryMethodSelect="deliveryMethodSelect"
                :key="i"
                :prevOrder="sortedOrders[i - 1]"
                :order="order"
                v-for="(order, i) in sortedOrders"
                :removeOrder="removeOrder"
                :selectedOrders="selectedOrders"
              />
            </fragment>
          </LoadingRowTable>
        </table>
      </fragment>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

import ShopPicker from '@/components/ShopPicker.vue'
import MainButton from '@/layout/MainButton.vue'
import OrderItem from '@/components/orders/OrderItem'
import LoadingRowTable from '@/components/LoadingRowTable.vue'
import SearchInput from '@/layout/SearchInput.vue'
import H2 from '@/layout/typography/H2.vue'
import DropdownSelect from '@/components/DropdownSelect.vue'

export default {
  components: {
    OrderItem,
    DatePicker,
    MainButton,
    ShopPicker,
    LoadingRowTable,
    SearchInput,
    H2,
    DropdownSelect,
  },
  created() {
    if (!this.isMobile) {
      this.showFilterMenu = true
    }

    if (window.location.href.split('#')[1]) {
      this.searchString = window.location.href.split('#')[1]
    }

    window.addEventListener('scroll', this.handleScroll)

    if (this.displayType == 'all') {
      this.archived = 'all'
    }

    this.getOrders()
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  props: ['displayType'],
  sockets: {
    addOrder(order) {
      this.orders.push(order)
    },
    bulkArchiveOrders(orderIds) {
      orderIds.forEach((orderId) => {
        let index = this.orders.findIndex((order) => order.orderId === orderId)
        this.orders.splice(index, 1)
      })
    },
    changeOrderStatus(data) {
      let index = this.orders.findIndex(
        (order) => order.orderId === data.orderId
      )
      this.orders[index].status = data.status
    },
    updateProductPackedStatus(data) {
      let index = this.orders.findIndex(
        (order) => order.orderId === data.orderId
      )
      let order = this.orders[index]
      let productIndex = order.products.findIndex(
        (product) => product.id === data.productId
      )
      if (data.checked) {
        order.products[productIndex].status = 1
      } else {
        order.products[productIndex].status = 0
      }
    },
    editOrder(data) {
      let orderIndex = this.orders.findIndex(
        (order) => order.orderId === data.orderId
      )
      Object.assign(this.orders[orderIndex], data)
    },
  },
  data() {
    return {
      orders: undefined,
      searchString: '',
      statusMobileMenu: false,
      shops: undefined,
      deliveryMethodSelect: null,
      selectedOrders: [],
      bulkSelect: false,
      archived: 0,
      packingStatus: 'all',
      bulkMenuButtonStyle: {
        top: '2px',
        position: 'relative',
        borderRadius: '25px',
        marginLeft: '15px',
        background: '#444',
        border: 'none',
      },
      dateRange: null,
      packedStatus: [],
      orderPage: 0,
      ordersIsLoading: false,
      orderIds: [],
    }
  },
  computed: {
    bulkMenuBottom() {
      if (this.selectedOrders.length > 0) {
        return 'transform: translateY(0)'
      }
    },
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
      isMobile: 'isMobile',
    }),
    sortedOrders() {
      if (this.orders) {
        return this.sorter(this.orders, [
          'datePickupDelivery',
          'name',
        ])
      } else {
        return []
      }
    },
    selectedShops() {
      return this.$store.state.selectedShops
    },
    orderArgs() {
      return {
        companyId: this.$store.state.selectedUser.companyId,
        archived: this.archived,
        confirmed: 1,
        packingStatus: this.packingStatus,
        selectedShops: this.selectedShops,
        deliveryMethod: this.deliveryMethodSelect,
        customerId: this.$route.params.customerId,
        searchString: this.searchString,
        orderPage: this.orderPage,
        packedStatus: this.packedStatus,
        dateRange: this.dateRange,
      }
    },
  },
  watch: {
    selectedOrders(data) {
      data = data.concat().sort()

      let orders = this.sortedOrders
        .map((order) => order.orderId)
        .concat()
        .sort()

      let orderIds = undefined

      if (this.orderIds) {
        orderIds = this.orderIds.concat().sort()
      }
      if (
        (orders.length > 0 && JSON.stringify(orders) == JSON.stringify(data)) ||
        (orderIds.length > 0 &&
          JSON.stringify(orderIds) == JSON.stringify(data))
      ) {
        this.bulkSelect = true
      } else {
        this.bulkSelect = false
      }
    },
    deliveryMethodSelect() {
      this.getOrders()
    },
    searchString() {
      this.getOrders()
    },
    packedStatus() {
      this.getOrders()
    },
    selectedShops() {
      this.getOrders()
    },
    dateRange() {
      this.getOrders()
    },
  },
  methods: {
    sorter(arr, keys) {
      keys.reverse().forEach((key) => {
        arr.sort((a, b) => {
          return a[key] > b[key] ? 1 : -1
        })
      })
      return arr
    },
    handleSelectAllOrders() {
      this.selectedOrders = this.orderIds
    },
    clearDateRange() {
      this.dateRange = null
    },
    changeBulkSelect(boolean) {
      if (boolean) {
        this.selectedOrders = []
        this.sortedOrders.forEach((order) => {
          this.selectedOrders.push(order.orderId)
        })
      } else {
        this.selectedOrders = []
      }
    },
    bulkArchive() {
      if (this.selectedOrders.length > 3) {
        if (
          confirm(
            `Are you sure you want to archive ${this.selectedOrders.length} orders?`
          )
        ) {
          if (this.selectedOrders.length > 0) {
            this.bulkArchiveEmit()
          }
        }
      } else {
        this.bulkArchiveEmit()
      }
    },
    bulkArchiveEmit() {
      this.$socket.emit('bulkArchiveOrders', {
        companyId: this.$store.state.selectedUser.companyId,
        selectedOrders: this.selectedOrders,
        user: this.$store.state.selectedUser,
      })
      this.selectedOrders = []
    },
    getOrders() {
      this.orders = undefined

      this.changeBulkSelect(false)
      this.getOrdersIds()
      this.orderPage = 0
      axios.post('orders/getOrders', this.orderArgs).then((res) => {
        this.orders = res.data
      })
    },
    getAndAppendOrders() {
      axios.post('orders/getOrders', this.orderArgs).then((res) => {
        this.orders.push(...res.data)
        this.ordersIsLoading = false
      })
    },
    getOrdersIds() {
      axios.post('orders/getOrdersIds', this.orderArgs).then((res) => {
        this.orderIds = res.data
      })
    },
    removeOrder(data) {
      this.orderWeeks.forEach((element, i) => {
        element.orders.forEach((order, j) => {
          if (order.orderId == data) {
            this.orderWeeks[i]['orders'].splice(j, 1)
          }
        })
      })
    },
    toggleMobileMenu() {
      this.statusMobileMenu = !this.statusMobileMenu
    },
    gotoView(page) {
      this.$router.push({ name: page })
    },
    handleScroll(e) {
      if (
        window.innerHeight + window.scrollY + 700 >=
        document.body.offsetHeight
      ) {
        if (!this.ordersIsLoading) {
          this.orderPage = this.orderPage + 1
          this.getAndAppendOrders()
        }

        this.ordersIsLoading = true
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import url('../../assets/css/table.css');

::v-deep .mx-datepicker-range {
  cursor: pointer;
  width: 210px;
}

::v-deep .mx-input {
  height: 27px !important;
  top: 0px;
  position: relative;
  cursor: pointer;
  font-size: 10pt;
  padding: 5px 10px;
  border-radius: 5px;
  margin-right: 10px;
  -webkit-box-shadow: inset 0px 0px 2px 1px rgba(219, 219, 219, 1);
  -moz-box-shadow: inset 0px 0px 2px 1px rgba(219, 219, 219, 1);
  box-shadow: inset 0px 0px 2px 1px rgba(219, 219, 219, 1);
  border: none;
}

.mx-datepicker-range {
  cursor: pointer;
  width: 210px;
}

.standardTable {
  table-layout: auto;
}

.standardTableHeader {
  transform: translateY(20px);
}

.bulkMenuWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.bulkMenu {
  display: flex;
  position: fixed;
  transition: 0.2s;
  z-index: 12;
  bottom: 50px;
  color: white;
  background: rgb(37, 37, 37);
  padding: 15px;
  transform: translateY(200px);
  border-radius: 35px;
}

.bulkMenu div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bulkSelectBox {
  background: #e9e9e9;
  width: 100%;
  border-radius: 5px;
  padding: 13px;
  margin-top: 10px;
  text-align: center;
  color: #333;
  font-weight: 400;
}

.bulkSelectBox span {
  color: #477cf5;
  font-weight: 500;
  font-family: 'Roboto';
  cursor: pointer;
}

.menuButton {
  padding: 5px 8px 2px 8px;
  background: #2694de;
  border-radius: 3px;
  border: none;
  color: white;
  font-family: 'Spartan';
  font-size: 9pt;
  width: 100%;
}

.mobileMenu {
  border: solid 1px #c0c0c0;
  border-radius: 5px;
  margin-top: 5px;
  position: absolute;
  right: 10px;
  background: #eaeaea;
}

.mobileMenu button {
  display: block;
  width: 100%;
  border: none;
  background: none;
  font-size: 10pt;
  border-bottom: 1px solid #c0c0c0;
}

.mobileMenu button:last-child {
  border-bottom: none;
}
</style>
