import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../vuex/store'
import { validate as uuidValidate } from 'uuid'
import AuthService from './../services/AuthService'

import ParentRouterView from './../components/sidebar/ParentRouterView.vue'

import Login from '../views/Login.vue'

const Home = () => import('../views/Home.vue')

const Dashboard = () => import('../views/dashboard/Dashboard')

const Products = () => import('../views/products/Products.vue')
const Product = () => import('../views/products/Product.vue')
const Groups = () => import('../views/products/Groups.vue')
const OpenGroup = () => import('../views/products/OpenGroup.vue')

const NewOrders = () => import('./../views/orders/NewOrders.vue')
import Orders from './../views/orders/Orders.vue'
const ArchivedOrders = () => import('../views/orders/ArchivedOrders.vue')
const TotalOrders = () => import('../views/orders/Total.vue')
const AddOrder = () => import('../views/orders/AddOrder.vue')
const EditOrder = () => import('../views/orders/EditOrder.vue')


const OpenCustomer = () => import('../views/customers/OpenCustomer.vue')
const Customers = () => import('../views/customers/Customers.vue')
const CustomerSettings = () => import('../views/customers/CustomerSettings.vue')

const Storage = () => import('../views/storage/Storage.vue')
const ViewStorageLocation = () => import('../views/storage/ViewStorageLocation.vue')
const Inventory = () => import('../views/storage/Inventory.vue')
const Batch = () => import('./../views/storage/Batch.vue')

const Employees = () => import('../views/management/Employees.vue')
const Employee = () => import('../views/management/Employee.vue')
const Roles = () => import('../views/management/Roles.vue')
const Role = () => import('../views/management/Role.vue')

const GeneralSettings = () => import('../views/settings/GeneralSettings.vue')
const CustomFields = () => import('../views/settings/CustomFields.vue')
const Pos = () => import('../views/pos/Pos.vue')
const Stores = () => import('../views/settings/Stores.vue')
const Store = () => import('../views/settings/Store.vue')
const StaticData = () => import('./../views/settings/StaticData.vue')

import ModuleService from './../services/moduleService'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/:companyId/',
    component: ParentRouterView,
    children: [
      {
        path: '/:companyId/home',
        name: 'Home',
        component: Home
      },
      {
        path: '/:companyId/dashboard',
        // name: 'DashboardParent',
        component: ParentRouterView,
        meta: {
          requiresAuth: true,
          requiresCorrectCompany: true
        },
        children: [
          {
            path: '/',
            name: 'Dashboard',
            component: Dashboard,
            meta: {
              requiresEmployeePermission: [6]
            }
          }
        ]
      },
      {
        path: '/:companyId/product',
        name: 'ProductsParent',
        component: ParentRouterView,
        meta: {
          requiresAuth: true,
          requiresCorrectCompany: true
        },
        children: [
          {
            path: 'all-products',
            name: 'Products',
            component: Products
          },
          {
            path: 'groups',
            name: 'Groups',
            component: Groups
          },
          {
            path: 'group/:groupId',
            name: 'OpenGroup',
            component: OpenGroup
          },
          {
            path: ':productId',
            name: 'Product',
            component: Product
          }
        ]
      },
      {
        path: '/:companyId/orders',
        name: 'OrdersParent',
        component: ParentRouterView,
        meta: {
          requiresAuth: true
        },
        children: [
          {
            path: 'new-orders',
            name: 'NewOrders',
            component: NewOrders
          },
          {
            path: 'all-orders',
            name: 'Orders',
            component: Orders
          },
          {
            path: 'archived-orders',
            name: 'ArchivedOrders',
            component: ArchivedOrders
          },
          {
            path: 'total',
            name: 'TotalOrders',
            component: TotalOrders
          },
          {
            path: 'add-order/:currentPage',
            name: 'AddOrder',
            component: AddOrder
          },
          {
            path: 'edit-order/:orderID',
            name: 'EditOrder',
            component: EditOrder
          }
        ]
      },
      {
        path: '/:companyId/customers',
        name: 'CustomersParent',
        component: ParentRouterView,
        meta: {
          requiresAuth: true,
          requiresModulePermission: 'customers'
        },
        children: [
          {
            path: 'all-customers',
            name: 'Customers',
            component: Customers,
            meta: {
              requiresEmployeePermission: [1]
            }
          },
          {
            path: 'customer-settings',
            name: 'CustomerSettings',
            component: CustomerSettings
          },
          {
            path: ':customerId',
            name: 'OpenCustomer',
            component: OpenCustomer,
            meta: {
              requiresEmployeePermission: [3]
            }
          }
        ]
      },
      {
        path: '/:companyId/storage',
        name: 'StorageParent',
        component: ParentRouterView,
        meta: {
          requiresAuth: true,
          requiresModulePermission: 'storage'
        },
        children: [
          {
            path: 'overview',
            name: 'Overview',
            component: Storage
          },
          {
            path: 'inventory/:shopId?',
            name: 'Inventory',
            component: Inventory
          },
          {
            path: 'storages/:storageLocationId',
            name: 'ViewStorageLocation',
            component: ViewStorageLocation
          },
          {
            path: 'batch/:batchId',
            name: 'Batch',
            component: Batch
          }
        ]
      },
      {
        path: '/:companyId/management',
        name: 'ManagementParent',
        component: ParentRouterView,
        meta: {
          requiresAuth: true
        },
        children: [
          {
            path: 'employees',
            name: 'Employees',
            component: Employees,
            meta: {
              requiresEmployeePermission: [4]
            }
          },
          {
            path: 'employees/:employeeId',
            name: 'Employee',
            component: Employee,
            meta: {
              requiresEmployeePermission: [4]
            }
          },
          {
            path: 'roles',
            name: 'Roles',
            component: Roles,
            meta: {
              requiresEmployeePermission: [5]
            }
          },
          {
            path: 'roles/:roleId',
            name: 'Role',
            component: Role,
            meta: {
              requiresEmployeePermission: [5]
            }
          }
        ]
      },
      {
        path: '/:companyId/settings',
        name: 'SettingsParent',
        component: ParentRouterView,
        meta: {
          requiresAuth: true
        },
        children: [
          {
            path: 'general',
            name: 'GeneralSettings',
            component: GeneralSettings
          },
          // TODO - Detta måste fixas
          {
            path: 'pos',
            name: 'Pos',
            component: Pos
          },
          {
            path: 'custom-fields',
            name: 'CustomFields',
            component: CustomFields
          },
          {
            path: 'stores',
            name: 'Stores',
            component: Stores
          },
          {
            path: 'store/:storeId',
            name: 'Store',
            component: Store
          },
          {
            path: 'static-data',
            name: 'StaticData',
            component: StaticData
          }
        ]
      }
    ]
  }
]

const router = new VueRouter({
  routes,
  mode: 'history',
  linkExactActiveClass: 'is-active',
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

router.beforeEach(async (to, from, next) => {
  //Clear custom messagebox when you leave
  store.commit('CLEAR_MESSAGEBOX')

  if (!to.matched[1] && !store.state.isLoggedIn) {
    if (to.name !== 'Login') {
      router.push({ name: 'Login' })
    }
  }

  if (!to.matched[2] && store.state.isLoggedIn) {
    router.push({
      name: 'Orders',
      params: { companyId: store.state.selectedUser.companyId }
    })
  }

  if (
    !store.state.loggedInUsers.length > 0 &&
    store.state.isLoggedIn &&
    store.state.token
  ) {
    store.dispatch('logout')
  }

  // TODO - Check functionality
  // Auto apply company id if entered in URL
  if (!store.state.selectedUser) {
    if (uuidValidate(to.params.companyId)) {
      const response = await AuthService.getCompanyInfo(to.params.companyId)
      return store.commit('SET_COMPANY', response)
    }
  }

  // Clear popup box
  if (store.state.popupComponent) {
    store.commit('CLEAR_POPUP')
    return next({ name: from.name })
  }

  // Company module auth
  if (to.matched.find(m => m.meta.requiresModulePermission)) {
    let moduleName = to.matched.find(m => m.meta.requiresModulePermission).meta
      .requiresModulePermission
    if (!ModuleService.hasModule(moduleName)) {
      return next({
        name: 'Orders',
        params: {
          companyId: store.state.selectedUser.companyId
        }
      })
    }
  }

  // If auth isn't required
  if (!to.matched.find(m => m.meta.requiresAuth)) {
    return next()
  }

  // If auth token is null forward to login
  if (store.state.token == null) {
    return next({ name: 'Login' })
  }

  // If user company id doesn't match URL comapny id
  if (!store.state.selectedUser.companyId === to.params.companyId) {
    return next({ name: 'Login' })
  }
  // If user hasn't the right permission to enter view forward to home
  if (to.matched.find(m => m.meta.requiresEmployeePermission)) {
    if (
      !AuthService.verifyPermission(
        to.matched.find(m => m.meta.requiresEmployeePermission).meta
          .requiresEmployeePermission
      )
    ) {
      return next({
        name: 'Orders',
        params: {
          companyId: store.state.selectedUser.companyId
        }
      })
    }
  }

  next()
})

export default router
