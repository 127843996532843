<template>
  <div class="alert" :style="getTypeStyles.box">
    <div>
      <div class="leftIcon">
        <ion-icon :style="getTypeStyles.text" :name="getTypeStyles.icon"></ion-icon>
      </div>
    </div>
    <div class="content">
      <h2 :style="getTypeStyles.text">{{ alert.title }}</h2>
      <p>{{ alert.description }}</p>
    </div>
    <div class="icon">
      <ion-icon @click="removeAlert()" name="close-outline"></ion-icon>
    </div>
  </div>
</template>

<script>
export default {
  props: ['alert', 'index'],
  methods: {
    removeAlert() {
      this.$store.commit('REMOVE_ALERT', this.index)
    },
  },
  created() {
    const _this = this
    if (this.alert?.time != null) {
      setTimeout(function () {
        _this.$store.commit('REMOVE_ALERT', this.index)
      }, this.alert.time)
    }
  },
  computed: {
    getTypeStyles() {
      switch (this.alert.type) {
        case 'error':
          return {
            box: {
              border: '1px solid #b35f5f',
              background: '#db9a9ae0',
            },
            text: {
              color: '#8d0e0e',
            },
            icon: 'alert-circle-outline',
          }
        case 'success':
          return {
            box: {
              border: '1px solid rgb(96 179 95)',
              background: '#9edb9ae0',
            },
            text: {
              color: '#12622b',
            },
            icon: 'checkmark-circle-outline',
          }
        default:
          return {
            box: {
              border: '1px solid grey',
              background: '#9abadbe0',
            },
            text: {
              color: '#123962',
            },
            icon: 'alert-circle-outline',
          }
      }
    },
  },
}
</script>

<style scoped>
@keyframes example {
  from {
    transform: translateX(40vw);
  }
  to {
    transform: translateX(0);
  }
}

.leftIcon ion-icon {
  margin: 0 20px;
  top: 2px;
  position: relative;
  font-size: 22pt;
}
.content p {
  color: #602727;
}
.content h2 {
  font-size: 12pt;
  color: #333;
}
.content {
  width: 100%;
  position: relative;
}
.icon {
  color: rgb(24, 24, 24);
  font-size: 30px;
  margin: 0 10px;
}
.icon ion-icon {
  cursor: pointer;
}
.alert {
  margin-left: auto;
  margin: 0 20px 20px 0;
  background: white;
  width: 32vw;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  animation-name: example;
  animation-duration: 0.4s;
  animation-timing-function: ease-in-out;
}
</style>
