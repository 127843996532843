<template>
  <div @contextmenu.prevent="closeMenu" @click="closeMenu" v-if="show" class="contextMenuWrapper">
    <div :style="style" class="contextMenu">
      <div
        @click="listItem.action"
        v-for="listItem in contextMenuList"
        :key="listItem.title"
        class="contextMenuItem"
      >
        <ion-icon
          v-if="listItem.icon"
          class="contextMenuItemIcon"
          :name="listItem.icon"
        ></ion-icon>
        <span class="contextMenuItemText">{{ listItem.title }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    contextMenuList: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      show: false,
      style: {}
    }
  },
  mounted() {
    document.querySelector('#app').appendChild(this.$el);
  },
  methods: {
    openMenu(e) {
      this.show = true
      document.addEventListener("keydown", this.closeOnEscKey);

      var x = e.pageX;
      var y = e.pageY;
      this.style.marginLeft = x + "px";
      this.style.marginTop = y + "px";

    },
    closeMenu() {
      this.show = false
    },
    closeOnEscKey(event) {
      if (event.keyCode === 27) this.closeMenu()
    },
  },
  created() {

  }
}
</script>

<style lang="scss" scoped>
.contextMenuWrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
}

.contextMenu {
  background: white;
  border-radius: 5px;
  position: absolute;
  z-index: 21;
  top: 0;
  left: 0;
  padding: 5px;
  -webkit-box-shadow: 0px 0px 2px 1px rgba(219, 219, 219, 1);
  -moz-box-shadow: 0px 0px 2px 1px rgba(219, 219, 219, 1);
  box-shadow: 0px 0px 2px 1px rgba(219, 219, 219, 1);
}

.contextMenuItem {
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 5px;
  border-radius: 4px;
  padding-right: 10px;
  padding-left: 7px;
}

.contextMenuItem:hover {
  background: #f1f1f1;
}

.contextMenuItem .contextMenuItemText {
  cursor: pointer;
  padding-left: 3px;
  color: #666;
  font-weight: bold;
  font-family: 'Spartan', sans-serif;
  font-size: 8pt;
  position: relative;
  top: 2px;
}

.contextMenuItem .contextMenuItemIcon {
  cursor: pointer;
  padding: 2px;
  border-radius: 3px;
  color: #666;
  font-weight: bold;
  font-family: 'Spartan', sans-serif;
  font-size: 12pt;
}
</style>
  
