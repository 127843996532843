<template>
  <div class="customFlexWrapper">
    <slot />
  </div>
</template>

<script>
export default {
}
</script>

<style>
.customFlexWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
</style>