<template>
  <div class="searchInputWrapper">
    <ion-icon name="search-outline"></ion-icon>
    <input
      type="text"
      :placeholder="placeholder"
      v-model="searchString"
    />
  </div>
</template>

<script>
export default {
  props: {
    placeholder: String
  },
  computed: {
    searchString: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  }
}
</script>

<style scoped>
.searchInputWrapper {
  background: none;
  border: none;
  position: relative;
  top: 3px;
  font-size: 10pt;
  border-radius: 7px;
  background-color: white;
  display: inline-flex;
  padding: 0 5px;
  -webkit-box-shadow: inset 0px 0px 2px 1px rgba(219, 219, 219, 1);
  -moz-box-shadow: inset 0px 0px 2px 1px rgba(219, 219, 219, 1);
  box-shadow: inset 0px 0px 2px 1px rgba(219, 219, 219, 1);
  user-select: none;
}

.searchInputWrapper input::-webkit-input-placeholder {
  color: #999;
}

.searchInputWrapper input {
  border: none;
  background: none;
  padding: 5px 0;
  width: 90px;
  transition: 1s;
  width: auto;
}

.searchInputWrapper ion-icon {
  font-size: 14pt;
  padding: 5px;
  color: #666;
  cursor: pointer;
}

.searchInputWrapper ion-icon:hover {
  color: #333;
}
@media screen and (max-width: 700px) {
  .searchInputWrapper {
    font-size: 8pt;
    top: -4px;
  }
}
</style>