<template>
  <div>
    <p class="dropdown" @click="toggle">
      <span>
        <ion-icon :name="icon" />
        {{ title }}
      </span>

      <ion-icon v-if="open" name="chevron-down-outline" />
      <ion-icon v-if="!open" name="chevron-forward-outline" />
    </p>

    <div class="submenu" v-if="open">
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      open: false,
    }
  },
  methods: {
    toggle() {
      this.open = !this.open
    },
  },
  props: ['icon', 'title'],
}
</script>

<style>
</style>