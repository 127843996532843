<template>
  <div :style="'margin:'+amount+'px'"></div>
</template>

<script>
export default {
  props: ["amount"]
}
</script>

<style>
</style>
