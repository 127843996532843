<template>
  <fragment>
    <fragment v-if="!dataList">
      <tr class="tableRow" v-for="index in 20" :key="index">
        <td :style="{padding: height+'px'}" v-for="index in columnsAmount" :key="index">
          <span></span>
        </td>
      </tr>
    </fragment>
    <fragment v-else>
      <slot />
      <td :colspan="columnsAmount" v-if="!dataList.length > 0">
        <div class="emptyStateWrapper">Empty</div>
      </td>
    </fragment>
  </fragment>
</template>

<script>

export default {
  props: ['columnsAmount', 'dataList', 'height'],
}
</script>

<style scoped>
.emptyStateWrapper {
  width: 100%;
  display: flex;
  padding: 40vh 0;
  justify-content: center;
}

.tableRow {
  background: linear-gradient(
    -45deg,
    #ffffff,
    #ffffff,
    #f1f1f1,
    #ffffff,
    #ffffff
  );
  background-size: 400% 400%;
  animation: gradient 1s linear infinite;
}

.tableRow td {
  padding: 15px;
  width: auto;
}

.tableRow td span {
  background: rgb(221, 221, 221);
  height: 13px;
  border-radius: 6px;
  width: 60%;
  display: block;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}
</style>