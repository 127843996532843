<template>
  <DropdownSelect title="Select shops" :items="shops" v-model="selectedShops" />
</template>

<script>
import axios from 'axios'
import DropdownSelect from './DropdownSelect.vue'

export default {
  components: { DropdownSelect },
  data() {
    return {
      shops: null,
      isOpen: false,
    }
  },
  computed: {
    selectedShops: {
      get() {
        return this.$store.state.selectedShops
      },
      set(data) {
        this.$store.commit('SET_SELECTED_SHOPS', data)
      },
    },
  },
  created() {
    this.getShops()
  },
  methods: {
    getShops() {
      axios
        .get('storage/getCompanyShops')
        .then((res) => {
          this.shops = res.data.map((el) => {
            el.value = el.id
            el.title = el.locationName
            return el
          })
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.dropdownWrapper {
  display: inline-flex;
  user-select: none;
}

.dropdownBar {
  display: inline-block;
  font: 400 13.3333px Arial;
  font-size: 10pt;
  padding: 5px 10px 6px 10px;
  border-radius: 5px;
  background: white;
  margin-right: 10px;
  box-shadow: inset 0px 0px 2px 1px rgb(219 219 219);
  border: none;
  align-items: center;
  display: inline-flex;
  cursor: context-menu;
  user-select: none;
}

.dropdownChevron {
  font-size: 12pt;
  margin-left: 10px;
  position: relative;
  color: black;
}

.dropdownContent {
  font-size: 10pt;
  padding: 10px;
  border-radius: 5px;
  margin-right: 10px;
  -webkit-box-shadow: inset 0px 0px 2px 1px rgba(219, 219, 219, 1);
  -moz-box-shadow: inset 0px 0px 2px 1px rgba(219, 219, 219, 1);
  box-shadow: inset 0px 0px 2px 1px rgba(219, 219, 219, 1);
  border: none;
  position: absolute;
  z-index: 10;
  background: white;
  margin-top: 30px;
}

.dropdownItem {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.dropdownItem label {
  cursor: pointer;
}

::v-deep .md-checkbox {
  margin: 5px;
}
::v-deep .md-checkbox .md-checkbox-container:before,
.md-checkbox .md-checkbox-container .md-ripple {
  height: 20px !important;
  width: 20px !important;
}
</style>
