<template>
  <div class="popupBox">
    <div class="popContent">
      <div class="popTopRow">
        <ion-icon
          style="cursor: pointer"
          @click="closePopup"
          name="close-outline"
        ></ion-icon>
      </div>
      <div class="popMiddleRow">
        <p>Login to your account</p>

        <div class="popMiddleInput">
          <p>Write your username</p>
          <input
            @keyup.enter="login"
            class="popupInput"
            type="text"
            placeholder="Username"
            v-model="username"
          />
        </div>
        <div class="popMiddleInput">
          <p>Write your password</p>
          <input
            @keyup.enter="login"
            class="popupInput"
            type="password"
            placeholder="Password"
            v-model="password"
          />
        </div>
      </div>

      <div class="popBottomRow">
        <MainButton
          :clickFunction="closePopup"
          title="Cancel"
          styleClass="secondary"
          :buttonStyle="buttonStyle"
        />
        <MainButton
          :clickFunction="login"
          title="Login"
          styleClass="primary"
          :buttonStyle="buttonStyle"
        />
      </div>
      <div v-if="errorMessage" class="errorMessage">
        {{ errorMessage }}
      </div>
    </div>
  </div>
</template>

<script>
import MainButton from '@/layout/MainButton.vue'
import AuthService from './../services/AuthService'
import ErrorHandler from '@/services/ErrorHandler'

export default {
  components: { MainButton },
  props: ['getShops'],
  data() {
    return {
      username: '',
      password: '',
      errorMessage: null,
      buttonStyle: {
        padding: '10px 25px 8px 25px',
        fontSize: '10pt',
      },
    }
  },
  methods: {
    async login(e) {
      if (e) {
        e.preventDefault()
      }
      try {
        const credentials = {
          username: this.username,
          companyId: this.$store.state.company.companyId,
          password: this.password,
        }
        const response = await AuthService.login(credentials)
        const token = response.token
        const user = response.user
        this.$store.dispatch('login', { token, user })
        this.$store.commit('CLEAR_POPUP')
      } catch (error) {
        this.errorMessage = ErrorHandler.returnErrorMessage(error)
      }
    },
    closePopup() {
      this.$store.commit('CLEAR_POPUP')
    },
  },
}
</script>

<style scoped src="@/assets/css/popup.css"></style>
<style scoped lang="scss">

.popContent {
  width: 100%;
  position: relative;
  display: inline;
}



/* Pop top row */

.popTopRow {
  width: 100%;
  text-align: right;
  font-size: 24pt;
}

/* Pop middle row */

.popMiddleRow {
  position: relative;
  margin: 10px;
  margin-top: 0px;
}
.popMiddleRow p {
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  font-size: 13pt;
  margin-bottom: 5px;
}

.popMiddleInput {
  position: relative;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.popMiddleInput p {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: normal;
  font-size: 9pt;
}

.popMiddleInput ion-icon {
  position: absolute;
  right: 0px;
  bottom: 50%;
  transform: translateY(100%);
}

/* Pop bottom row */

.popBottomRow {
  position: relative;
  margin: 10px;
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
}
</style>