<template>
  <div id="topBar">
    <h2 v-if="!isMobile" class="logoText">Aviliax OMS</h2>
    <h3
      class="logoText"
      @click="logout"
      style="cursor: pointer"
      :title="'Logout ' + this.$store.state.selectedUser.username"
    >
      {{ this.$store.state.selectedUser.username }}
    </h3>
    <div style="display: flex">
      <div @click="openLoginBox" class="profilePicture">
        <ion-icon name="add-outline" class="addIcon"></ion-icon>
      </div>
      <div
        @click="
          () => {
            selectUser(user)
          }
        "
        v-for="user in this.$store.state.loggedInUsers"
        :key="user.id"
        class="profilePicture"
      >
        <img
          :src="`https://eu.ui-avatars.com/api/?background=random&name=${
            user.username.split('.')[0]
          }+${user.username.split('.')[1]}`"
          alt=""
        />
      </div>
    </div>
    <!-- <div :style="profileStatusFunction" class="profileStatus">
      <section>
        <div @click="updateStatus('online')"></div>
        <div @click="updateStatus('away')"></div>
        <div @click="updateStatus('offline')"></div>
      </section>
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LoginPopup from './LoginPopup.vue'

export default {
  data() {
    return {
      status: 'online',
      openProfileStatus: false,
    }
  },
  created() {
    if (this.$store.state.loggedInUsers[0] === "") {
      this.$store.dispatch('logout')
    }
  },
  components: {
    LoginPopup
  },
  computed: {
    ...mapGetters({
      isMobile: 'isMobile',
    }),
    profileStatusFunction() {
      if (this.openProfileStatus) {
        return { transform: 'translateX(0)' }
      } else {
        return { transform: 'translateX(100px)' }
      }
    },
    statusStyle() {
      if (this.status == 'online') {
        return { background: 'green' }
      } else if (this.status == 'away') {
        return { background: 'orange' }
      } else if (this.status == 'offline') {
        return { background: 'gray' }
      }
    },
  },
  methods: {
    openLoginBox() {
      this.$store.commit('SET_POPUP', { component: LoginPopup })
    },
    selectUser(user) {
      this.$store.commit('SET_SELECTED_USER', user)
    },
    updateStatus(data) {
      this.status = data
      this.openProfileStatus = !this.openProfileStatus
    },
    logout() {
      this.$store.dispatch('logout')
    },
    openBox() {
      this.openProfileStatus = !this.openProfileStatus
    },
  },
}
</script>

<style>
#topBar {
  background: rgb(23, 24, 26);
  width: 100%;
  position: fixed;
  float: right;
  z-index: 11;
  -webkit-box-shadow: 0px 0px 4px -2px rgba(125, 125, 125, 0.8);
  -moz-box-shadow: 0px 0px 4px -2px rgba(125, 125, 125, 0.8);
  box-shadow: 0px 0px 4px -2px rgba(125, 125, 125, 0.8);
  display: flex;
  justify-content: space-between;
  height: 50px;
}

.usernameBox {
  background: #333;
  height: 70%;
  border-bottom-left-radius: 10px;
  color: white;
  padding: 0 20px;
  padding-top: 5px;
  font-family: 'Spartan';
  cursor: pointer;
}

#topBar .logoText {
  margin: 0;
  font-weight: normal;
  font-size: 20pt;
  font-family: 'Spartan';
  height: 30px;
  padding: 10px;
  position: relative;
  top: 2px;
  margin-left: 20px;
  margin-top: 5px;
  color: #fff;
}

.profilePicture {
  position: relative;
  float: right;
  width: 35px;
  height: 35px;
  margin: 6px;
  border-radius: 50%;
  text-align: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  background: rgb(173, 173, 173);
}

.addIcon {
  margin: auto;
  font-size: 15pt;
}

.profileStatus {
  position: absolute;
  float: right;
  right: 10px;
  bottom: -15px;
  z-index: 10;
  transition: 0.2s;
  transform: translateX(100px);
}
.profileStatus section {
  display: flex;
}

.profileStatus section div {
  display: flex;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin: 2px;
}
.profileStatus section div:nth-child(1) {
  background: green;
}
.profileStatus section div:nth-child(2) {
  background: orange;
}
.profileStatus section div:nth-child(3) {
  background: gray;
}

.status {
  width: 6px;
  height: 6px;
  border-radius: 3px;
  position: absolute;
  right: 0;
  bottom: 0;
}

.statusGreen {
  background: green;
}

@media screen and (max-width: 700px) {
  #topBar .logoText {
    font-size: 11pt;
  }
}
</style>