<template>
  <div class="bottomNavBar">
    <div class="menuButton" @click="toggleSidebar(); animateButtonClick($event)">
      <i class="fa fa-bars"></i>
      <p>Menu</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "MobileNavbar",
  props: ["toggleSidebar"],
  computed: {
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
    }),
  },
  data() {
    return {
      focused: false,
    };
  },
  methods: {
    animateButtonClick(e) {
      e.target.closest('.menuButton').classList.add("animationClass")
      setTimeout(() => {
        e.target.closest('.menuButton').classList.remove("animationClass")
      }, 300);
    }
  }
};
</script>

<style scoped lang="scss">
.bottomNavBar {
  position: fixed;
  width: 100%;
  background: white;
  bottom: 0;
  left: 0;
  display: inline-flex;
  padding: 0 10px;
  z-index: 10;
  overflow: hidden;
  -webkit-box-shadow: 0px 0px 11px -8px rgba(148, 148, 148, 1);
  -moz-box-shadow: 0px 0px 11px -8px rgba(148, 148, 148, 1);
  box-shadow: 0px 0px 11px -8px rgba(148, 148, 148, 1);
}

.bottomNavBar div {
  padding: 10px;
  text-align: center;
  margin: auto;
  width: 80px;
}

.bottomNavBar div i {
  font-size: 16pt;
  padding-bottom: 5px;
  color: #444;
}

.bottomNavBar div p {
  font-size: 9pt;
  font-family: "Montserrat", sans-serif;
  position: relative;
  user-select: none;
  margin: 0;
}



.animationClass::after {
  content: "";
  position: absolute;
  margin: -35px 0 0 -35px;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
}

.animationClass::after {
  background: rgba(44, 44, 44, 0.1);
}

.animationClass::after {
  animation: anim-effect-boris 0.3s forwards;
}

@keyframes anim-effect-boris {
  0% {
    transform:  translateY(-18px) scale3d(0.3, 0.3, 1);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform:  translateY(-18px) scale3d(1.2, 1.2, 1);
  }
}
</style>