
export default {
  bind(el, binding, vNode) {
    if (!binding.value) throw new TypeError('[v-context-menu] | Requires a value')
    if (!(typeof binding.value === "string")) throw new TypeError('[v-context-menu] | Requires type to be string')
    if (!vNode.context.$refs[binding.value]) throw new TypeError('[v-context-menu="' + binding.value + '"] | Could not find context menu ref')
    let contextMenu = vNode.context.$refs[binding.value];

    let listener = (event) => {
      event.stopPropagation();
      event.preventDefault();
      contextMenu.openMenu(event);
    };
    
    el.addEventListener("contextmenu", listener);
    document.addEventListener("click", () => {
      if (contextMenu.show) contextMenu.closeMenu()
    });

  },
}
