import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import { initSocketConnection, validateSession } from '../main'

import router from './../router'

import createPersistedState from 'vuex-persistedstate'
import SecureLS from 'secure-ls'
import VueSocketIO from 'vue-socket.io'
var ls = new SecureLS({
  encodingType: 'rc4',
  isCompression: false,
  encryptionSecret: 'r89RnWwJUuudXPSwaJyCdvSv7zVJ5G4ajKwBGbqAZr3mx7JQ4mX',
})

Vue.use(Vuex)

let state = {
  company: null,
  loggedInUsers: [],
  alerts: [],
  selectedUser: {},
  posOrders: [],
  token: null,
  isLoggedIn: false,
  selectedShops: [],
  selectedShop: null,
  popup: null,
  companyModules: [],
  messageBox: null,
  addOrderInformation: null,
  inventory: {
    inventoryPath: [],
    groupPerStorageStatus: false,
  },
}

let mutations = {
  SET_IS_LOGGED_IN: (state, payload) => {
    state.isLoggedIn = payload
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  ADD_USER: (state, user) => {
    state.loggedInUsers.push(user)
  },
  ADD_ALERT: (state, alert) => {
    state.alerts.push(alert)
  },
  REMOVE_ALERT: (state, index) => {
    state.alerts.splice(index, 1)
  },
  REMOVE_USER: (state, user) => {
    let index = state.loggedInUsers.findIndex((el) => el.id == user.id)
    state.loggedInUsers.splice(index, 1)
  },
  CLEAR_USERS: (state) => {
    state.loggedInUsers = []
  },
  ADD_POS_PRODUCT: (state, payload) => {
    let index = state.posOrders.findIndex((x) => x.employeeId == payload.id)
    if (index != -1) {
      state.posOrders[index].products = [payload.product].concat(
        state.posOrders[index].products
      )
    } else {
      state.posOrders.push({ employeeId: payload.id, products: [] })
      index = state.posOrders.findIndex((x) => x.employeeId == payload.id)
      state.posOrders[index].products.push(payload.product)
    }
  },
  UPDATE_POS_PRODUCTS: (state, payload) => {
    let index = state.posOrders.findIndex((x) => x.employeeId == payload.id)
    if (index != -1) {
      state.posOrders[index].products = payload.products
    }
  },
  REMOVE_POS_PRODUCTS: (state, payload) => {
    let index = state.posOrders.findIndex((x) => x.employeeId == payload.id)
    if (index != -1) {
      state.posOrders[index].products = []
    }
  },
  REMOVE_POS_PRODUCT: (state, payload) => {
    let index = state.posOrders.findIndex((x) => x.employeeId == payload.id)
    state.posOrders[index].products.splice(payload.index, 1)
  },
  CLEAR_POS_PRODUCT: (state) => {
    state.posOrders = []
  },
  SET_SELECTED_USER: (state, user) => {
    state.selectedUser = user
    axios.defaults.headers.common['Authorization'] = `Bearer ${user.token}`
  },
  RESET: (state) => {
    Object.assign(state, getDefaultState())
  },
  SET_SELECTED_SHOP: (state, payload) => {
    state.selectedShop = payload
  },
  SET_SELECTED_SHOPS: (state, payload) => {
    state.selectedShops = payload
  },
  SET_POPUP(state, payload) {
    state.popup = payload
  },
  CLEAR_POPUP(state) {
    state.popup = null
  },
  SET_COMPANY_MODULES(state, payload) {
    state.companyModules = payload
  },
  SET_COMPANY(state, payload) {
    state.company = payload
  },
  SET_MESSAGEBOX(state, payload) {
    state.messageBox = payload
  },
  CLEAR_MESSAGEBOX(state, payload) {
    state.messageBox = null
  },
  SET_ADD_ORDER_INFORMATION(state, payload) {
    state.addOrderInformation = payload
  },
  SET_INVENTORY_PATH(state, payload) {
    state.inventory.inventoryPath = payload
  },
  CLEAR_INVENTORY_PATH(state) {
    state.inventory.inventoryPath = []
  },
  TOGGLE_GROUP_PER_STORAGE_STATUS(state) {
    state.inventory.groupPerStorageStatus =
      !state.inventory.groupPerStorageStatus
  },
}

const getDefaultState = () => {
  return {
    token: null,
    isLoggedIn: false,
    selectedShops: [],
    popupComponent: null,
    companyModules: [],
    inventory: {
      inventoryPath: [],
      groupPerStorageStatus: false,
    },
  }
}

// ======================
//        ACTIONS
// ======================
const actions = {
  login: ({ state, commit }, { user }) => {
    if (state.loggedInUsers[0] === null) {
      state.loggedInUsers = []
    }

    initSocketConnection()

    if (state.loggedInUsers.find((el) => el.id === user.id)) {
      commit('SET_SELECTED_USER', user)
    } else {
      commit('ADD_USER', user)
    }

    commit('SET_TOKEN', user.token)
    commit('SET_SELECTED_USER', user)
    commit('SET_IS_LOGGED_IN', true)
    axios.defaults.headers.common['Authorization'] = `Bearer ${user.token}`

    validateSession()
  },
  logout: ({ state, commit }) => {
    delete axios.defaults.headers.common['Authorization']

    if (state.loggedInUsers.length > 1) {
      commit('REMOVE_USER', state.selectedUser)
      commit('SET_SELECTED_USER', state.loggedInUsers[0])
    } else {
      commit('SET_SELECTED_USER', {})
      commit('CLEAR_USERS')
      commit('RESET', '')
      if (router.currentRoute.name !== 'Login') {
        router.push({ name: 'Login' })
      }
    }
  },
}

// ====================== //
//        GETTERS         //
// ====================== //

const getters = {
  isLoggedIn: (state) => {
    return state.token
  },
  getAlerts: (state) => {
    return state.alerts
  },
  getUser: (state) => {
    return state.selectedUser
  },
  getLocationId: (state) => {
    return state.selectedShop
  },
  isMobile() {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      return true
    } else {
      return false
    }
  },
  getInventoryPath: (state) => {
    return state.inventory.inventoryPath
  },
}

const store = new Vuex.Store({
  strict: false,
  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
  state,
  getters,
  mutations,
  actions,
})

export default store
