import axios from 'axios';
import store from './../vuex/store'

export default {
  login(credentials) {
    return axios
      .post('auth/login/', credentials)
      .then(response => response.data);
  },
  getCompanyInfo(companyId) {
    return axios
      .post('auth/getCompanyInfo/', { companyId })
      .then(response => response.data);
  },
  verifyPermission(neededPermissions) {
    if (store.state.selectedUser.admin == 1) {
      return true
    }
    let employeePermissions = store.state.selectedUser.userPermissions
    if (neededPermissions.every(el => employeePermissions?.includes(el))) {
      return true
    }
    return false
  }
};